import React, { useEffect } from 'react';
import { Row, Col } from './ResponsiveGrid';
import SideNavigation from './navigation/SideNavigation';
import { useDispatch, useSelector } from 'react-redux';
import {checkAndSetActiveAccount} from '../../services/authenticationServices';
import { tenantConfigSelector } from '../../store/slices/tenantConfigReducer';
 
const MainLayout : React.FunctionComponent<{}> = props => {
  const dispatch = useDispatch();
  const { tenantConfig } = useSelector(tenantConfigSelector);

  useEffect(() => {
    dispatch(checkAndSetActiveAccount(tenantConfig));
  }, [dispatch]);
 
  return (
      <Row style={{ alignContent: `stretch`, flexGrow: 3, margin: `0`}} fullWidth>
        <Col xs={12} md={2} style={{flex: `0 0 10em`}}>
            <SideNavigation/>
        </Col>
        <Col xs={12} md={10} filled style={{flex: `2 2 20em`}}>
            {props.children}
        </Col> 
    </Row>
  );
};

export default MainLayout;