export interface ISecondaryColorsJson {
    alert: string;
    defaultColor: string;
}
export class SecondaryColors {
    public static fromJson = (json?: ISecondaryColorsJson) => {
        if (json) {
            return new SecondaryColors(
                json.alert, 
                json.defaultColor
            );
        }

        return new SecondaryColors();
    }

    constructor(
        public alert: string = "",
        public defaultColor: string = "", // Cannot use "default" because it is a reserved phrase within javascript
    ) {        
    }
}