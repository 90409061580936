import React, { useEffect, useState } from 'react';
import Loader from './Loader';

type DelayedLoaderProps = {
    component: JSX.Element;
    delay: number;
    loadingMessage?: string;
}

function DelayedLoader(props: DelayedLoaderProps) {
    const { component, delay, loadingMessage } = props;
    const [content, setContent] = useState(<Loader loadingMessage={loadingMessage ? loadingMessage : ''} />);
    
    useEffect(() => {
        const timer = setTimeout(() => {
            setContent(component);
        }, delay);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return content;
  };
  
  export default DelayedLoader;