import React from "react";
import App from './App';
import { OrgThemeContext } from './shared/OrgThemeContext';
import AppRoutes from './AppRoutes';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import MainLayout from './layout/MainLayout';
import { useSelector } from "react-redux";
import { tenantConfigSelector } from "../store/slices/tenantConfigReducer";
import DelayedLoader from "./shared/DelayedLoader";
import PrivateTenantContext from "./PrivateTenantContext";

const OrganizationContext = ({ orgId }) => {
  const { tenantConfig } = useSelector(tenantConfigSelector);
  
  return (
    tenantConfig && 
      <OrgThemeContext tenantConfig={tenantConfig}>
        <App tenantConfig={tenantConfig} orgId={orgId}>
          <AuthenticatedTemplate>
            <MainLayout>
              <PrivateTenantContext>
                <AppRoutes orgId={orgId} />
              </PrivateTenantContext>
            </MainLayout>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            {/* Display a loading animation for a few seconds to give the app time to authenticate before displaying the message below */}
            <DelayedLoader
              delay={2000}
              component={<div>Please login for your organizational page.</div>}
            />
          </UnauthenticatedTemplate>
        </App>
      </OrgThemeContext>
  );
}

export default OrganizationContext;