import { ThemeContext } from 'styled-components';
import  React, {useContext} from "react";

// Tech Debt: Add TenantConfig Type to the "tenantConfig" parameter (instead of the default "any" type)
export const OrgThemeContext = ({ children, tenantConfig }) => {
    let themeContext = useContext(ThemeContext);
    let modifiedthemeContext:Object;
   
    // Check to see if the Props exsits in the json file, which we need to style
    if (typeof tenantConfig.customUI.brandingColors !== `undefined` && typeof tenantConfig.customUI.brandingColors.primary !== `undefined` && typeof tenantConfig.customUI.brandingColors.secondary !== `undefined`) {
      modifiedthemeContext = {...themeContext, 
        colors: {...themeContext.colors,  primary:{...tenantConfig.customUI.brandingColors.primary}, secondary:{...tenantConfig.customUI.brandingColors.secondary}},
        fonts: [...themeContext.fonts],
        fontSizes:{...themeContext.fontSizes},
        deviceSizes:{...themeContext.deviceSizes}
      };
    } else {
    // If not just leave the default theme
      modifiedthemeContext = {...themeContext};
    }
   
    return <ThemeContext.Provider value={modifiedthemeContext}> {children} </ThemeContext.Provider>;  
  };