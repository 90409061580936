export interface IPrimaryColorsJson {
    main: string;
    light: string;
    xlight: string;
}

export class PrimaryColors {
    public static fromJson = (json?: IPrimaryColorsJson) => {
        if (json) {
            return new PrimaryColors(
                json.main, 
                json.light,
                json.xlight
            );
        }

        return new PrimaryColors();
    }

    constructor(
        public main: string = ``,
        public light: string = ``,
        public xlight: string = ``,
    ) {        
    }
}