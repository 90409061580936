import * as React from 'react';
import { useParams } from 'react-router-dom';
import OrganizationContext from './OrganizationContext';
import ErrorPage from './pages/misc/ErrorPage';
 
function AuthenticationWrapper() {
  let { data } = useParams();
  // The following cases are needed for the client's sign-in behavior:
  // 1. /{orgId} - optionally followed by subpaths but those won't be part of 'data'.
  //    In this case we just render an OrganizationContext and provide it the organization ID.
  // 2. If neither of the above cases is matched, we show an error message to the user.

  if (data.match(/^[A-Za-z0-9]+\.[A-Za-z0-9]+$/)) {
    // Case 1: The path provides an organization ID.
    const orgId = data;
    return (
      <OrganizationContext orgId={orgId} />
    );
  }
  // Case 2: The URL format is invalid.
  return (
    <ErrorPage title="Unexpected URL format">
      <p>It's not clear how you arrived at this page. Please contact your IT department and provide them with a copy of the following information:</p>
      <pre>
        URL Fragment: {data}<br />
        Location: {window.location.href}
      </pre>
    </ErrorPage>
  );
}

export default AuthenticationWrapper;