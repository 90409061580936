import { CustomAttributeType } from "../../models/enums/CustomAttributeType";
import { TenantConfig } from "../../models/TenantConfig";

export interface AttributeFilter {
    name: string;
    type: CustomAttributeType;
    value: Array<string>;
  }
export interface FilterCriteria {
    keyword: string;
    issuerAssignedId: string;
    enabled: AttributeFilter;
    customAttributes: Array<AttributeFilter>;
    pageNumber: number;
    isDefault: boolean;
}
export function filterCriteriaToServerModel(filter: FilterCriteria, tenantConfig: TenantConfig): FilterCriteria {
    let isDefault = isDefaultFilterCriteria(filter);
    filter.isDefault = isDefault;
    let copiedFilter = {...filter, 
        enabled: {...filter.enabled}, 
        customAttributes: filter.customAttributes.map(attribute => {
        return {...attribute}; 
        }
    )};
    copiedFilter.customAttributes.forEach(attributeFilter => {
        switch (attributeFilter.type) {
        case CustomAttributeType.Dropdown:
            attributeFilter.value = attributeFilter.value.map(attribute => {
            let tenantAttribute = tenantConfig.customAttributes.find(tAttribute => tAttribute.name === attributeFilter.name);
            if (tenantAttribute) {
                var option = tenantAttribute.options.find(x => x.clientValue === attribute);
                if (option && option.serverValue) {
                    return option.serverValue;
                } 
            }
            return attribute;
            });
            break;
        default:
            break;
        }
    })
    return copiedFilter;
}
export function filterCriteriaToEditorServerModel(filter: FilterCriteria): FilterCriteria {
    let copiedFilter = {...filter, 
        enabled: {...filter.enabled} };
    return copiedFilter;
}

export function getDefaultFilterCriteria (): FilterCriteria {
    let enabledFilter: AttributeFilter = {
        name: `Enabled`,
        type: CustomAttributeType.Bool,
        value: []
    }
    return { keyword: ``, issuerAssignedId: ``, enabled: enabledFilter, customAttributes: [], pageNumber: 1, isDefault: true };
}

export function isDefaultFilterCriteria (filter: FilterCriteria): boolean {
    let customAttributesWithFilters = filter.customAttributes.filter(ca => ca.value.filter(x => x !== ``).length > 0);
    return filter.keyword === `` && filter.issuerAssignedId === ``
      && filter.enabled.value.length === 0 && customAttributesWithFilters.length === 0;
  }

export function deepCopyFilterCriteria (filterCriteria: FilterCriteria) {
    return {...filterCriteria, customAttributes: filterCriteria.customAttributes.map(ca => {
        return {...ca, value: ca.value.map(x => x)}
      }) };
}