import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { ErrorBoundary } from "./pages/misc/ErrorBoundary";
import ErrorPage from "./pages/misc/ErrorPage";
import OrganizationLoader from "./shared/OrganizationLoader";
import { authenticatedUserSelector } from "../store/slices/authentication";
import { tenantConfigSelector } from "../store/slices/tenantConfigReducer";
import Report from "./pages/auditLogs/Report";

const UsersList = lazy(() => import(`./pages/users/UsersList`));
const UserDetails = lazy(() => import(`./pages/users/UserDetails`));

function AppRoutes(props) {
  const { orgId } = props;
  const { path, url } = useRouteMatch();
  const urlNoTrailingSlash = url.charAt(url.length-1) === `/` ? url.slice(0, -1) : url;
  const { accessToken, userRole } = useSelector(authenticatedUserSelector);
  const { tenantConfig } = useSelector(tenantConfigSelector);

  return (
    accessToken && !userRole ? 
      <ErrorPage title="Could not load organization configuration.">
      <p>Contact your administrator to be assigned a role within the B2C HelpDesk.</p>
      <pre>
        Tenant ID: {orgId}<br />
        Location: {window.location.href}<br />
        {/* Response Status Code: {reason?.status}<br />
        Response: {JSON.stringify(reason)} */}
      </pre>
    </ErrorPage> :
    <ErrorBoundary
      fallback={
        <ErrorPage title="Could not load application module.">
          <p>Please contact your IT department and provide them with the following information:</p>
          <pre>
            Tenant ID: {orgId}<br />
            Location: {window.location.href}<br />
          </pre>
        </ErrorPage>
      }
    >
      <Suspense fallback={
        <OrganizationLoader
          loadingMessage="Loading module"
        />
      }>
        <Switch>
          <Route path={`${path}/dashboard/`}>
              <pre>{JSON.stringify(tenantConfig, null, 2)}</pre>
          </Route>
          <Route exact path={`${path}/users/`} >
              <UsersList orgId={orgId} tenantConfig={tenantConfig} userRole={userRole} />
          </Route>
          <Route path={`${path}/users/create-new-user`}>
              <UserDetails orgId={orgId}/>
          </Route>
          <Route path={`${path}/users/user-edit`}>
              <UserDetails orgId={orgId} />
          </Route>     
          <Route path={`${path}/report/`}>
              <Report orgId={orgId} tenantConfig={tenantConfig} userRole={userRole} />
          </Route>
          <Route path="*" render={() => (
              <Redirect to={`${urlNoTrailingSlash}/users/`} />
          )} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
    
  );
}

export default AppRoutes;