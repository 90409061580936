import React from 'react';
import {Stack} from '@fluentui/react';
import styled from "styled-components";
import { lighten } from 'polished'
import { Col, Row } from '../layout/ResponsiveGrid';
import { ShimmerStylingExample } from './Shimmer';
import { IAccountActivity } from '../../models/AccountActivity';
import { ActivityType, UserManagerActivityType } from '../../models/enums/ActivityType';
import { ISignIn } from '../../models/SignIn';
import { IUserAccountActivity } from '../../models/UserAccountActivity';
import { TenantConfig } from '../../models/TenantConfig';

const StyledActivityLog = styled.div`
  background:${({ theme: { colors } }) => colors.white};

  @media ${({ theme: { deviceSizes } }) => deviceSizes.maxMobileXL} { 
    border-width: 0 !important;
  } 
  .ms-Stack {       
    @media ${({ theme: { deviceSizes } }) => deviceSizes.minMobile} { 
        padding: 10px 0;
    }
    @media ${({ theme: { deviceSizes } }) => deviceSizes.minTablet} { 
        padding: 10px 0 10px 10px;
    } 
  }
  .user-activity-type{font-size:12px;}
  .user-activity { padding: 10px;  }
  .user-activity:nth-child(odd) { background:${({ theme: { colors } }) => lighten(0.075, colors.greys.xxlight)}; }
  h4 { font-weight: 500; margin: 5px 0 0 0; 
    @media ${({ theme: { deviceSizes } }) => deviceSizes.minMobile} { 
        display: block;
        width: 100%;
        border-bottom: 1px solid ${({ theme: { colors } }) => colors.greys.xdark};
    }
    @media ${({ theme: { deviceSizes } }) => deviceSizes.minTablet} {         
        width: auto;
        border-bottom: 0;
    }
  };  
  ul { margin-top: 5px; padding: 0; }
  li { list-style-type: none; padding: 0 5px 5px 0; };
  strong { font-weight: 500; };
`;

const StyledList = styled.ul`margin-top:0;margin-bottom:0; font-size:12px;`

export interface IActivityLogProps {
    userActivities: Array<IAccountActivity>;
    correspondingActivities: Array<IAccountActivity>;
    borderWidth?: string;
    tenantConfig: TenantConfig
}

function ActivityLog(props: IActivityLogProps) {
    let organizedActivities: Array<IAccountActivity> = new Array<IAccountActivity>();
    let allActivities: Array<IAccountActivity> = new Array<IAccountActivity>();
    allActivities = allActivities.concat(props.correspondingActivities);
    let groupedActivities = props.userActivities.reduce((groups, item) => {
        const group = (groups[item.correlationId] || []);
        group.push(item);
        groups[item.correlationId] = group;
        return groups;
      }, {});

    for(var key in groupedActivities) {
        var value = groupedActivities[key];
        let sorted = value.sort((a,b) => b.date.valueOf() - a.date.valueOf());
        organizedActivities.push(sorted[0]);
        allActivities = allActivities.concat(sorted);
    }
    var activities = organizedActivities.filter(x => x !== undefined).sort((a,b) => b.date.valueOf() - a.date.valueOf()).map((activity, index) => {
        let activityContent:JSX.Element | undefined = undefined;
        let activityCategory:string = ``;                  
        switch (activity.activityType) {
            case ActivityType.SignIn:
                const signIn: ISignIn = activity as ISignIn;
                activityCategory = activity.activityType;
                let correspondingActivities = allActivities
                    .filter(cActivity => cActivity.correlationId === activity.correlationId);
                activityContent = (
                    <StyledList>
                        {signIn.date && <li><strong>Time:</strong> {signIn.date.toLocaleDateString()} at {signIn.date.toLocaleTimeString()}</li>}
                        <li><strong>Application:</strong> {correspondingActivities.length > 0 ? (correspondingActivities[0] as IUserAccountActivity).applicationName : signIn.appDisplayName}</li>
                        <li><strong>Policy:</strong> {correspondingActivities.length > 0 ? (correspondingActivities[0] as IUserAccountActivity).policyId : ""}</li>
                        <li><strong>Status:</strong> {signIn.status}</li>
                        <li><strong>Location:</strong> {signIn.city}, {signIn.state}</li>
                        <li><strong>IP Address:</strong> {signIn.ipAddress}</li>
                        <li><strong>Actions:</strong> 
                            {correspondingActivities.sort((a,b) => b.date.valueOf() - a.date.valueOf()).map(
                                userAccountActivity => 
                                    <ol id={"1"}>{userAccountActivity.date && <li>{userAccountActivity.date.toLocaleTimeString()}: <strong>{(userAccountActivity as IUserAccountActivity).activityDisplayName ? (userAccountActivity as IUserAccountActivity).activityDisplayName : (userAccountActivity as ISignIn).activityType}</strong> </li>}
                                    <ol id={"2"}>Result: {(userAccountActivity as IUserAccountActivity).activityResult ? (userAccountActivity as IUserAccountActivity).activityResult : (userAccountActivity as ISignIn).status}</ol></ol>)}
                                
                        </li>
                        
                    </StyledList>
                );
                break;
            case ActivityType.Created:
            case ActivityType.Updated:
            case ActivityType.Deleted:
            case ActivityType.Other:
                const userActivity: IUserAccountActivity = activity as IUserAccountActivity;
                activityCategory = userActivity.category;
                if (userActivity.advancedUserManagerActivity){
                    switch(userActivity.activityDisplayName) {
                        case UserManagerActivityType.Create:
                            activityContent = (
                                <StyledList>
                                    {userActivity.date && (
                                        <li><strong>Time: </strong> 
                                        {userActivity.date.toLocaleDateString()} at {userActivity.date.toLocaleTimeString()}
                                        </li>
                                    )}
                                    <li><strong>Activity:</strong> {userActivity.activityDisplayName}</li>
                                    <li><strong>Created By:</strong> {userActivity.loggedByService}</li>
                                    <li><strong>Customer:</strong> {userActivity.userEdited}</li>
                                </StyledList>
                            );
                            break;
                        case UserManagerActivityType.Impersonate:
                            activityContent = (
                                <StyledList>
                                    {userActivity.date && (
                                        <li><strong>Time: </strong> 
                                        {userActivity.date.toLocaleDateString()} at {userActivity.date.toLocaleTimeString()}
                                        </li>
                                    )}
                                    <li><strong>Activity:</strong> {userActivity.activityDisplayName}</li>
                                    <li><strong>Impersonated By:</strong> {userActivity.loggedByService}</li>
                                    <li><strong>Customer:</strong> {userActivity.userEdited}</li>
                                </StyledList>
                            );
                            break;
                        case UserManagerActivityType.ResetPassword:
                            activityContent = (
                                <StyledList>
                                    {userActivity.date && (
                                        <li><strong>Time: </strong> 
                                        {userActivity.date.toLocaleDateString()} at {userActivity.date.toLocaleTimeString()}
                                        </li>
                                    )}
                                    <li><strong>Activity:</strong> {userActivity.activityDisplayName}</li>
                                    <li><strong>Reset By:</strong> {userActivity.loggedByService}</li>
                                    <li><strong>Customer:</strong> {userActivity.userEdited}</li>
                                </StyledList>
                            );
                            break;
                        case UserManagerActivityType.Update:
                            var updates = userActivity.updatedValues.map(update => {
                                var tenantAttribute = props.tenantConfig.customAttributes.find(att => att.name === update.attribute);
                                update.attribute = tenantAttribute && tenantAttribute.clientValue ? tenantAttribute?.clientValue : update.attribute;
                                return update;
                            });
                            activityContent = (
                                <StyledList>
                                    {userActivity.date && (
                                        <li><strong>Time: </strong> 
                                        {userActivity.date.toLocaleDateString()} at {userActivity.date.toLocaleTimeString()}
                                        </li>
                                    )}
                                    <li><strong>Activity:</strong> {userActivity.activityDisplayName}</li>
                                    <li><strong>Updated By:</strong> {userActivity.loggedByService}</li>
                                    <li><strong>Customer:</strong> {userActivity.userEdited}</li>
                                    <li><strong>Updates:</strong>{updates.map((update, index) => <ol key={index.toString()}><strong>{update.attribute}</strong>: {update.oldValue} <b>{"->"}</b> {update.newValue}</ol>)}
                                    </li>
                                </StyledList>
                            );
                            break;
                        default:
                            break;
                    }
                } else {
                    if (props.correspondingActivities.findIndex(x => x.correlationId === activity.correlationId) === -1) {
                        activityContent = (
                            <StyledList>
                                {userActivity.date && (
                                    <li><strong>Time: </strong> 
                                    {userActivity.date.toLocaleDateString()} at {userActivity.date.toLocaleTimeString()}
                                    </li>
                                )}
                                <li><strong>Activity:</strong> {userActivity.activityDisplayName}</li>
                                <li><strong>Application:</strong> {userActivity.applicationName}</li>
                                <li><strong>Policy:</strong> {userActivity.policyId}</li>
                                <li><strong>Result:</strong> {userActivity.activityResult}</li>
                                <li><strong>Logged By:</strong> {userActivity.loggedByService}</li>
                            </StyledList>
                        );
                        break;
                    }
                }
                break;
            default:
                break;
        }
        if (activity.activityType !== undefined && activityContent !== undefined) {
            return (
                <Row key={index} className={'user-activity'}>
                    <Col xs={12} md={3} lg={2} className={'user-activity-type'}>
                        <strong>
                            {activityCategory !== `` ? activityCategory : undefined}
                        </strong>
                    </Col>
                    <Col xs={12} md={9} lg={11} className={'user-activity-detail'}>
                        {activityContent}
                    </Col>
                </Row>
            )
        } else {
            return undefined;  
        }
    }).filter(x => x !== undefined);

    return (
        <StyledActivityLog
            style={{ borderWidth: props.borderWidth }}
        >
            <Stack horizontal>
                <Stack.Item grow>

                    {/* Loading Data */}
                    {(props.userActivities.length > 0) && (props.userActivities.find(activity => activity.activityType === undefined)) && (
                        <ShimmerStylingExample />
                    )}
                    {/* Data returned but found no activity */}
                    {(props.userActivities.length === 0) && (
                        <h4>No Recent Account Activity</h4>
                    )}
                    {/* Data returned */}
                    {activities}
                </Stack.Item>
            </Stack>
        </StyledActivityLog>
    );
  }
  
  export default ActivityLog;
  