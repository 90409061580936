import { IAccountActivity, IAccountActivityJson } from "./AccountActivity";
import { ActivityType, getActivityType } from "./enums/ActivityType";

export interface IEdit {
    oldValue: string;
    newValue: string;
    attribute: string;
}
export class Edit implements IEdit {
    constructor(
        public attribute: string = "",
        public oldValue: string = "",
        public newValue: string = ""
    ){}

}
export function getValueUpdate(update: any): Edit {
    return new Edit(update.attribute, update.oldValue, update.newValue)
}


export interface IUserAccountActivity extends IAccountActivity {
    activityDisplayName: string;
    date: Date;
    activityResult: string;
    category: string;
    loggedByService: string;
    userEdited: string;
    domainName: string;
    policyId: string;
    applicationName: string;
    advancedUserManagerActivity: boolean;
    updatedValues: Array<Edit>;
}

export interface IUserAccountActivityJson extends IAccountActivityJson {
    activityDisplayName: string;
    activityDateTime: Date;
    activityResult: string;
    category: string;
    loggedByService: string;
    userEdited: string;
    domainName: string;
    policyId: string;
    applicationName: string;
    advancedUserManagerActivity: boolean;
    updatedValues: any;
}

export class UserAccountActivity {
    public static fromJson = (json?: IUserAccountActivityJson) => {
        if (json) {
            return new UserAccountActivity(
                json.activityType ? getActivityType(json.activityType) : undefined,
                json.userId,
                json.correlationId,
                json.activityDisplayName,
                json.activityDateTime,
                json.activityResult,
                json.category,
                json.loggedByService,
                json.domainName,
                json.policyId,
                json.applicationName,
                json.advancedUserManagerActivity,
                json.updatedValues ? json.updatedValues.map(x => getValueUpdate(x)) : [],
                json.userEdited
            );
        }
        return new UserAccountActivity();
    }
    constructor(
        public activityType: ActivityType | undefined = undefined,
        public userId: string = "",
        public correlationId: string = "",
        public activityDisplayName: string = "",
        public date: Date = new Date(),
        public activityResult: string = "",
        public category: string = "",
        public loggedByService: string = "",
        public domainName: string = "",
        public policyId: string = "",
        public applicationName: string = "",
        public advancedUserManagerActivity: boolean = false,
        public updatedValues: Array<Edit> = [],
        public userEdited: string = ""
    ) {}
}