import { CustomAttributeType } from "../models/enums/CustomAttributeType";
import { FieldName } from "../models/enums/FieldName";
import { SignInType } from "../models/enums/SignInType";
import { TenantConfig } from "../models/TenantConfig";
import { IUser } from "../models/User";
import { getEnabledIdentities, getUsernameCharacterLimitFromDropdownPermissions } from "./userHelpers";

/**
 * Check if the provided value is numeric in nature (since "isNaN" will respond to null values, empty strings & empty arrays by claiming that they ARE numbers when in fact they are not)
 * @param value 
 */
export const valueIsNumeric = (value: any): boolean => {    
    let stringValue: string | undefined = undefined;
    if (typeof value === "string") {
        stringValue = value.trim();
    }
    let result = (!isNaN(value) && 
            (value !== null) && 
            ((stringValue === undefined) || (stringValue !== "")) && 
            !Array.isArray(value)
    );
    return result;
};

export const validateString = (required: boolean, value?: string): string => {    
    if (required && !value) {
        return `Required`;
    }
    return ``;
}

export const validateUsername = (required: boolean, user: IUser, tenantConfig: TenantConfig, value?: string): string => {
    if (required && !value) {
        return `Required`;
    }

    if (value) {
        const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEx.test(value + "@test.com")) {
            return `Invalid Username`;
        }
        if (user && tenantConfig) {
            var characterLimit = getUsernameCharacterLimitFromDropdownPermissions(user, tenantConfig);
            if (characterLimit && !isNaN(characterLimit) && value.length > characterLimit) {
                return `Username must not exceed ` + characterLimit + ` characters`;
            }
        }
    }
    
    return '';
}

export const validateEmail = (required: boolean, value?: string): string => {
    if (required && !value) {
        return `Required`;
    }
    if (value) {
        const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEx.test(value) ? '' : `Invalid Email`;
    }
    return '';
}

export const validateNumericString = (required: boolean, value?: string): string => {
    if (required && !value) {
        return `Required`;
    }
    if (value && !valueIsNumeric(value)) {
        return `Invalid`;
    }
    return ``;
}

export const validatePasswordRequirements = (required: boolean, value: string, passwordLength?: number): string => { 

    const regEx = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#!@$%^&*()\-_+={}[\]|\\:;"'<>,.?/]).{0,24}$/g;

    let isMeetingRequirements:Boolean = regEx.test(value);
    if (required && !value) {
        return `Required`;
    }
    if (required && value && value.length < passwordLength!){
        return `Password too short`;
    }
    if(required && value && value.length >= passwordLength! && (isMeetingRequirements === false)){
        return `Must meet password requirements`;
    }
    return ``;
}

export const validateStringsEquivalent = (caseSensitive:boolean, valueA: string, valueB: string ): string => { 
    if (!valueA && !valueB) {
        return ``;
    }
    if (caseSensitive && (valueA === valueB)) {
        return ``;
    }
    if (!caseSensitive && (valueA.toLowerCase() === valueB.toLowerCase())){
        return ``;
    }
    return `Does not match!`;
}
 
export function isUserInvalid(userId: string, user: IUser, tenantConfig: TenantConfig): boolean {

    // A federated user is only presented with custom attributes that are editable, so always allow save
    // and return that the user is not invalid.
    if (user.identities && user.identities.some(x => x.signInType === SignInType.Federated)) {
        return false;
    }

    // First and last name validation on create - cannot be empty
    var firstOrLastNameInvalidOnCreate = !userId && (!user.firstName || !user.lastName);
    // First and last name validation on update - cannot be empty when editable
    var firstOrLastNameInvalidWhenEditableOnUpdate = (userId != "" && ((!user.firstName && !tenantConfig.azureAttributeConfiguration.firstNameReadOnly) 
        || (!user.lastName && !tenantConfig.azureAttributeConfiguration.lastNameReadOnly)));
        
    var { usernameIdentityEnabled, emailIdentityEnabled } = getEnabledIdentities(user, tenantConfig);
    // Email address validation
    var emailAddressIsRequired = emailIdentityEnabled && !usernameIdentityEnabled;
    var emailAddressIsNotValid = validateEmail(emailAddressIsRequired, user.email) !== '';

    // Username validation
    var usernameIsRequired = !emailIdentityEnabled && usernameIdentityEnabled;
    var usernameIsNotValid = validateUsername(usernameIsRequired, user, tenantConfig, user.userName) !== ''
    
    // Identity validation when email and username enabled - At least one of username or email must not be empty
    var signinMethodNotProvidedForUser = !user.email && !user.userName && emailIdentityEnabled && usernameIdentityEnabled;

    return firstOrLastNameInvalidOnCreate
        || firstOrLastNameInvalidWhenEditableOnUpdate
        || signinMethodNotProvidedForUser
        || emailAddressIsNotValid
        || usernameIsNotValid;
}