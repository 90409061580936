import { createSlice } from '@reduxjs/toolkit';
// set intial state for users
export const initialState = {
  loading: false,
  hasErrors: false,
  allUsers: [],
};

const usersSlice = createSlice({
  name: `users`,
  initialState,
  reducers: {
    initializeGetUsers: state => {
      state.loading = true;
    },
    getUsersSuccess: (state, { payload }) => {
      state.allUsers = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getUsersFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { initializeGetUsers, getUsersSuccess, getUsersFailure} = usersSlice.actions;
export const usersSelector = state => state.users;
export default usersSlice.reducer;
