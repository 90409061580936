import axios from 'axios';
import { FilterCriteria, filterCriteriaToServerModel, isDefaultFilterCriteria } from '../components/shared/FilterCriteria';
import { apiUrl } from '../configurations/config';
import { iUserFromServerModel, iUserToServerModel } from '../helpers/userHelpers';
import { FilterApi, TenantConfig } from '../models/TenantConfig';
import { IUser } from '../models/User';

export async function getFilteredUsers(organizationId: string, filterCriteria: FilterCriteria, accessToken:string, tenantConfig: TenantConfig, groupsAccessToken: string): Promise<any> {
  const headers = {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'OrganizationId': `${organizationId}`,
      'GroupsAuthorization': `Bearer ${groupsAccessToken}` 
    }
  };
  try {
    let url = `${apiUrl}/api/org/${organizationId}/user/GetFilteredUsers/`;
    const response = await axios.post(url, filterCriteriaToServerModel(filterCriteria, tenantConfig), headers); // Tech Debt: pass a string directly instead of using a user object
    if (response.data) {
        response.data.users = response.data.users.map(user => iUserFromServerModel(user, tenantConfig));
        return response.data;
    } else {
        var error = new Error(`Unexpected error while fetching user`);
        (error as any).response = response.data;
        throw error;
    }
  } catch (main_error) { 
    throw errorResponse(main_error);
  }
}

export async function getFilterApiCall(filterApi: FilterApi, tenantConfig: TenantConfig, filterCriteria: FilterCriteria): Promise<any> {
  try {
    const response = await axios.post(filterApi.endpointUrl, filterApi.value);
    if (response.status && response.status > 200) {
      throw new Error(`Unexpected error while fetching api data`);
    }
    if (response.data) {
      var tenantAttribute = tenantConfig.customAttributes.find(x => x.name === filterApi.correlatedExtensionAttribute)
      var values = response.data;
      if (tenantAttribute) {
      
        filterCriteria.customAttributes.forEach(x => {
          if (tenantAttribute && x.name === tenantAttribute.name) {
            x.value = values;
          }
        });
        filterCriteria.isDefault = isDefaultFilterCriteria(filterCriteria);
        return filterCriteria;
      }
    } else {
      let error = new Error(`Unexpected error while fetching api data`);
        (error as any).response = response.data;
        throw error;
    }
  } catch (main_error) { 
    let error = new Error(`Unexpected error while fetching api data`);
        (error as any).response = `Unexpected error while fetching api data`;
        throw error;
  }
}



export async function getAllUsers(organizationId: string, accessToken:string, tenantConfig: TenantConfig, groupsAccessToken: string): Promise<any> {
  const headers = {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'OrganizationId': `${organizationId}`,
      'GroupsAuthorization': `Bearer ${groupsAccessToken}`  
    }
  };
  try {
    let url = `${apiUrl}/api/org/${organizationId}/user/GetAllUsers/`;
    const response = await axios.get(url, headers);
    if (response.data) {
        return response.data.map(user => iUserFromServerModel(user, tenantConfig));
    } else {
        var error = new Error(`Unexpected error while fetching users`);
        (error as any).response = response.data;
        throw error;
    }
  } catch (main_error) { 
    throw errorResponse(main_error);
  }
}

export async function createUser(organizationId: string, accessToken:string, user: IUser, tenantConfig: TenantConfig, groupsAccessToken: string): Promise<any> {
  const headers = {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'OrganizationId': `${organizationId}`,
      'GroupsAuthorization': `Bearer ${groupsAccessToken}` 
    }
  };
  try {   
    const response = await axios.post(`${apiUrl}/api/org/${organizationId}/user/CreateUser/`, iUserToServerModel(user, tenantConfig), headers);
    if (response.data) {
        return iUserFromServerModel(response.data, tenantConfig);
    } else {
        var error = new Error(`Unexpected error while creating user`);
        (error as any).response = response.data;
        throw error;
    }
  } catch (main_error) {
    throw errorResponse(main_error);
  }
}

export async function resetUserPassword(organizationId: string, accessToken:string, user: IUser, tenantConfig: TenantConfig, groupsAccessToken: string): Promise<any> {
  const headers = {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'OrganizationId': `${organizationId}`,
      'GroupsAuthorization': `Bearer ${groupsAccessToken}` 
    }
  };
  try {
        const response = await axios.post(`${apiUrl}/api/org/${organizationId}/user/ResetPassword/`, iUserToServerModel(user, tenantConfig), headers);
        if (response.data) {
            return iUserFromServerModel(response.data, tenantConfig);
        } else {
            var error = new Error(`Unexpected error while updating user`);
            (error as any).response = response.data;
            throw error;
        }
    } catch (main_error) {
      throw errorResponse(main_error);
    }
}

export async function getAuthenticatedUserRole(organizationId: string, accessToken:string, groupsAccessToken: string): Promise<any> {
  const headers = {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'OrganizationId': `${organizationId}`,
      'GroupsAuthorization': `Bearer ${groupsAccessToken}`
    }
  };
  try {
    let url = `${apiUrl}/api/org/${organizationId}/user/GetAuthenticatedUserRole/`;
    const response = await axios.get(url, headers); 
    if (response.data) {
        return response.data;
    } else {
        var error = new Error(`Unexpected error while fetching role`);
        (error as any).response = response.data;
        throw error;
    }
  } catch (main_error) { }
}

export async function getUserbyId(organizationId: string, accessToken:string, user: IUser, tenantConfig: TenantConfig, groupsAccessToken: string): Promise<any> {
  const headers = {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'OrganizationId': `${organizationId}`,
      'GroupsAuthorization': `Bearer ${groupsAccessToken}` 
    }
  };
  try {
    let url = `${apiUrl}/api/org/${organizationId}/user/GetUserbyId/`;
    const response = await axios.post(url, iUserToServerModel(user, tenantConfig), headers); // Tech Debt: pass a string directly instead of using a user object
    if (response.data) {
        return iUserFromServerModel(response.data, tenantConfig);
    } else {
        var error = new Error(`Unexpected error while fetching user`);
        (error as any).response = response.data;
        throw error;
    }
  } catch (main_error) { }
}

export async function updateUser(organizationId: string, accessToken:string, user: IUser, tenantConfig: TenantConfig, groupsAccessToken: string): Promise<any> {
  const headers = {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'OrganizationId': `${organizationId}`,
      'GroupsAuthorization': `Bearer ${groupsAccessToken}` 
    }
  };
  try {   
    const response = await axios.post(`${apiUrl}/api/org/${organizationId}/user/UpdateUser/`, iUserToServerModel(user, tenantConfig), headers);
    if (response.data) {
        return iUserFromServerModel(response.data, tenantConfig);
    } else {
        var error = new Error(`Unexpected error while updating user`);
        (error as any).response = response.data;
        throw error;
    }
  } catch (main_error) {
    throw errorResponse(main_error);
  }
}

export async function logImpersonate(organizationId: string, accessToken:string, user: IUser, tenantConfig: TenantConfig, groupsAccessToken: string): Promise<any> {
  const headers = {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'OrganizationId': `${organizationId}`,
      'GroupsAuthorization': `Bearer ${groupsAccessToken}` 
    }
  };
  try {   
    const response = await axios.post(`${apiUrl}/api/org/${organizationId}/user/LogImpersonate/`, iUserToServerModel(user, tenantConfig), headers);
    if (response.data) {
        return true;
    } else {
        var error = new Error(`Unexpected error while updating user`);
        (error as any).response = response.data;
        throw error;
    }
  } catch (main_error) {
    throw errorResponse(main_error);
  }
}

export async function getUserSignIns(organizationId: string, accessToken:string, user: IUser, tenantConfig: TenantConfig, groupsAccessToken: string): Promise<any> {
  const headers = {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'OrganizationId': `${organizationId}`,
      'GroupsAuthorization': `Bearer ${groupsAccessToken}` 
    }
  };
  try {
    let url = `${apiUrl}/api/org/${organizationId}/user/GetUserSignIns/`;
    const response = await axios.post(url, iUserToServerModel(user, tenantConfig), headers);
    if (response.data) {
        return response.data;
    } else {
        var error = new Error(`Unexpected error while fetching user`);
        (error as any).response = response.data;
        throw error;
    }
  } catch (main_error) { }
}

export async function getUserAccountActivity(organizationId: string, accessToken:string, user: IUser, tenantConfig: TenantConfig, groupsAccessToken: string): Promise<any> {
  const headers = {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'OrganizationId': `${organizationId}`,
      'GroupsAuthorization': `Bearer ${groupsAccessToken}` 
    }
  };
  try {
    let url = `${apiUrl}/api/org/${organizationId}/user/GetUserAccountActivity/`;
    const response = await axios.post(url, iUserToServerModel(user, tenantConfig), headers);
    if (response.data) {
        return response.data;
    } else {
        var error = new Error(`Unexpected error while fetching user activity`);
        (error as any).response = response.data;
        throw error;
    }
  } catch (main_error) { }
}

export async function getUserManagerAccountActivity(organizationId: string, accessToken:string, userOrEditorId: string, logsEndpointUrl: string, groupsAccessToken: string): Promise<any> {
  const headers = {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'OrganizationId': `${organizationId}`,
      'GroupsAuthorization': `Bearer ${groupsAccessToken}` 
    }
  };
  try {
    const response = await axios.get(logsEndpointUrl+`&userId=${userOrEditorId}`, headers);
    if (response.data) {
        return response.data;
    } else {
        var error = new Error(`Unexpected error while fetching user activity`);
        (error as any).response = response.data;
        throw error;
    }
  } catch (main_error) { }
}

export async function getFilteredEditors(organizationId: string, filterCriteria: FilterCriteria, accessToken:string, tenantConfig: TenantConfig, groupsAccessToken: string): Promise<any> {
  const headers = {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'OrganizationId': `${organizationId}`,
      'Keyword': filterCriteria.keyword,
      'IssuerAssignedId': filterCriteria.issuerAssignedId,
      'GroupsAuthorization': `Bearer ${groupsAccessToken}`
    }
  };
  try {
    const response = await axios.get(tenantConfig.auditLogConfiguration.filteredEditorsEndpoint, headers);
    if (response.data) {
        response.data.users = response.data.users.map(user => iUserFromServerModel(user, tenantConfig));
        return response.data;
    } else {
        var error = new Error(`Unexpected error while fetching user`);
        (error as any).response = response.data;
        throw error;
    }
  } catch (main_error) { 
    throw errorResponse(main_error);
  }
}

export async function exportReport(organizationId: string, accessToken:string, userId: string, exportReportUrl: string, groupsAccessToken: string): Promise<any> {
  const headers = {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'OrganizationId': `${organizationId}`,
      'UserId': userId,
      'GroupsAuthorization': `Bearer ${groupsAccessToken}`
    }
  };
  try {
    const response = await axios.get(exportReportUrl, headers);
    if (response.data) {
        return response.data;
    } else {
        var error = new Error(`Unexpected error while exporting report`);
        (error as any).response = response.data;
        throw error;
    }
  } catch (main_error) { }
}

export async function deleteUserById(organizationId: string, accessToken:string, user: IUser, tenantConfig: TenantConfig, groupsAccessToken: string): Promise<any> {
  const headers = {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'OrganizationId': `${organizationId}`,
      'GroupsAuthorization': `Bearer ${groupsAccessToken}` 
    }
  };
  try {
    let url = `${apiUrl}/api/org/${organizationId}/user/DeleteUser/`;
    const response = await axios.post(url, iUserToServerModel(user, tenantConfig), headers); // Tech Debt: pass a string directly instead of using a user object
    if (response.data) {
        return response.data;
    } else {
        var error = new Error(`Unexpected error while fetching user`);
        (error as any).response = response.data;
        throw error;
    }
  } catch (main_error) {
    throw errorResponse(main_error);
  }
}

function errorResponse(main_error): Error {
  var error = new Error(main_error.message);
  (error as any).response = main_error.response;
  (error as any).status = main_error.response.status;
  if (main_error.response.status === 401 || main_error.response.status === 403) {
    // If 401 Unauthorized or 403 Forbidden response returned from api
    error.message = main_error.message + ": User is Unauthorized."
  } else if (main_error.response.status === 500)
  {
    // If 500 Internal Server Error response returned from api
    error.message = "Request failed with status code 500."
  } else if (main_error.response.status === 400) {
    error.message = main_error.message + ": " + main_error.response.data.message;
  }
  return error;
}