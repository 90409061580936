import { getBooleanFromValue } from "../helpers/booleanHelpers";
import { valueIsNumeric } from "../helpers/validationHelpers";
import { BrandingColors, IBrandingColorsJson } from "./BrandingColors";
import { BrandingLogos, IBrandingLogosJson } from "./BrandingLogos";
import { BrandingText, IBrandingTextJson } from "./BrandingText";
import { TenantAttribute, ITenantAttributeJson } from "./CustomAttribute";
import { getOrganizationSizeFromString, OrganizationSize } from "./enums/OrganizationSize";
import { getResetPasswordPolicyFromString, ResetPasswordPolicy } from "./enums/ResetPasswordPolicy";

export class FilterApi {
    constructor(
        public id: number = 0,
        public correlatedExtensionAttribute: string = "",
        public inputName: string = "",
        public endpointUrl: string = "",
        public value: string = ""
    ) {}
}

export interface IFilterApi { 
    CorrelatedExtensionAttribute: string;
    InputName: string;
    EndpointUrl: string;
}

export class AuditLogConfiguration { 
    constructor(
        public enableAuditLogging: boolean = false,
        public enableEditorAuditLogging: boolean = false,
        public enableAuditLogReports: boolean = false,
        public logsEndpoint: string = "",
        public filteredEditorsEndpoint: string = "",
        public exportReportEndpoint: string = ""
    ) {}
}

export interface IAuditLogConfiguration { 
    EnableAuditLogging: string;
    EnableEditorAuditLogging: string;
    EnableAuditLogReports: string;
    LogsEndpoint: string;
    FilteredEditorsEndpoint: string;
    ExportReportEndpoint: string;
}

export class Group { 
    constructor(
        public groupId: string = "",
        public name: string = "",
        public role: string = ""

    ) {}
}

export interface IGroup { 
    GroupId: string;
    Name: string;
    Role: string;
}

export class ImpersonationUrl { 
    constructor(
        public name: string = "",
        public url: string = ""
    ) {}
}

export interface IImpersonationUrl { 
    Name: string;
    Url: string;
}

export class CustomUI {
    constructor(
        public appDescription: string = "",
        public appName: string = "",
        public appShortName: string = "",
        public brandingColors: BrandingColors = new BrandingColors(),
        public brandingLogos: BrandingLogos = new BrandingLogos(),
        public brandingText: BrandingText = new BrandingText(),
        public signInTabText: string = "Sign In Activity",
        public organizationSize: OrganizationSize = OrganizationSize.Large,
        public pageResultSize: number = defaultPageResultSize,
        public showAllAttributeColumns: boolean = false,
        public useLocalStorage: boolean = true,
        public disableNotificationAutoComplete: boolean = true
    ) {}
}

export interface ICustomUI {
    appDescription: string;
    appName: string;
    appShortName: string;
    brandingColors: IBrandingColorsJson;
    brandingLogos: IBrandingLogosJson;
    brandingText: IBrandingTextJson;   
    SignInTabText: string;
    OrganizationSize: string;
    PageResultSize: string; 
    ShowAllAttributeColumns: string;
    UseLocalStorage: string;
    DisableNotificationAutoComplete: string;
}

export class PasswordConfiguration {
    constructor(
        public passwordMinimumLength: number = defaultPasswordMinimumLength,
        public resetPasswordPolicy: ResetPasswordPolicy = ResetPasswordPolicy.ManualPassword
    ) {}
}

export interface IPasswordConfiguration {
    PasswordMinimumLength: string;
    ResetPasswordPolicy: ResetPasswordPolicy;
}

export class ImpersonationConfiguration {
    constructor(
        public impersonationPolicy: string = "",
        public impersonationClientId: string = "",
        public impersonationLandingUrl: string = "",
        public directImpersonationUrl: string = "",
        public directImpersonationUrls: Array<ImpersonationUrl> = []
    ) {}
}

export interface IImpersonationConfiguration {
    ImpersonationPolicy: string;
    ImpersonationClientId: string;
    ImpersonationLandingUrl: string;
    DirectImpersonationUrl: string;
    DirectImpersonationUrls: Array<IImpersonationUrl>;
}

export class AzureAttributeConfiguration {
    constructor(
        public disableCreate: boolean = false,
        public deleteDisabled: boolean = false,
        public firstNameReadOnly: boolean = false,
        public lastNameReadOnly: boolean = false,
        public emailAddressEnabled: boolean = true,
        public usernameEnabled: boolean = false,
    ) {}
}

export interface IAzureAttributeConfiguration {
    DisableCreate: string;
    DeleteDisabled: string;
    FirstNameReadOnly: string;
    LastNameReadOnly: string;
    EmailAddressEnabled: string;
    UsernameEnabled: string;
}

export interface ITenantConfigJson { 
    AzureAdTenantId: string;
    B2cDomainName: string;
    CustomUI: ICustomUI;
    CustomAttributes: Array<ITenantAttributeJson>;
    AzureAttributeConfiguration: IAzureAttributeConfiguration;
    PasswordConfiguration: IPasswordConfiguration;
    ImpersonationConfiguration: IImpersonationConfiguration;
    Groups: Array<IGroup>;
    AuditLogConfiguration: IAuditLogConfiguration;
    FilterApis: Array<IFilterApi>;
}

export const defaultPasswordMinimumLength = 8;
export const defaultPageResultSize = 25;

export class TenantConfig {
    public static fromJson = (isPrivateConfiguration: boolean, json?: ITenantConfigJson, orgId?: string) => {
        if (json) {
            return new TenantConfig(
                orgId ? orgId : undefined,
                isPrivateConfiguration,
                json.AzureAdTenantId,
                json.B2cDomainName,
                json.CustomUI ? 
                    new CustomUI(
                        json.CustomUI.appDescription,
                        json.CustomUI.appName,
                        json.CustomUI.appShortName,
                        BrandingColors.fromJson(json.CustomUI.brandingColors),
                        BrandingLogos.fromJson(json.CustomUI.brandingLogos),
                        BrandingText.fromJson(json.CustomUI.brandingText),
                        json.CustomUI.SignInTabText,
                        getOrganizationSizeFromString(json.CustomUI.OrganizationSize),
                        valueIsNumeric(json.CustomUI.PageResultSize) ? Number(json.CustomUI.PageResultSize) : defaultPageResultSize,
                        json.CustomUI.ShowAllAttributeColumns ? getBooleanFromValue(json.CustomUI.ShowAllAttributeColumns) : false,
                        json.CustomUI.UseLocalStorage ? getBooleanFromValue(json.CustomUI.UseLocalStorage) : true,
                        json.CustomUI.DisableNotificationAutoComplete ? getBooleanFromValue(json.CustomUI.DisableNotificationAutoComplete) : false
                    ) : new CustomUI(),
                json.CustomAttributes ? json.CustomAttributes.map(TenantAttribute.fromJson) : [],
                json.AzureAttributeConfiguration ? 
                    new AzureAttributeConfiguration(
                        json.AzureAttributeConfiguration.DisableCreate ? getBooleanFromValue(json.AzureAttributeConfiguration.DisableCreate) : false,
                        json.AzureAttributeConfiguration.DeleteDisabled ? getBooleanFromValue(json.AzureAttributeConfiguration.DeleteDisabled) : false,
                        json.AzureAttributeConfiguration.FirstNameReadOnly ? getBooleanFromValue(json.AzureAttributeConfiguration.FirstNameReadOnly) : false,
                        json.AzureAttributeConfiguration.LastNameReadOnly ? getBooleanFromValue(json.AzureAttributeConfiguration.LastNameReadOnly) : false,
                        json.AzureAttributeConfiguration.EmailAddressEnabled != undefined ? getBooleanFromValue(json.AzureAttributeConfiguration.EmailAddressEnabled) : true,
                        json.AzureAttributeConfiguration.UsernameEnabled ? getBooleanFromValue(json.AzureAttributeConfiguration.UsernameEnabled) : false
                    ) : new AzureAttributeConfiguration(),
                json.PasswordConfiguration ? 
                    new PasswordConfiguration(
                        valueIsNumeric(json.PasswordConfiguration.PasswordMinimumLength) ? Number(json.PasswordConfiguration.PasswordMinimumLength) : defaultPasswordMinimumLength,
                        getResetPasswordPolicyFromString(json.PasswordConfiguration.ResetPasswordPolicy),
                    ) : new PasswordConfiguration(),
                json.ImpersonationConfiguration ? 
                    new ImpersonationConfiguration(
                        json.ImpersonationConfiguration.ImpersonationPolicy,
                        json.ImpersonationConfiguration.ImpersonationClientId,
                        json.ImpersonationConfiguration.ImpersonationLandingUrl,
                        json.ImpersonationConfiguration.DirectImpersonationUrl,
                        json.ImpersonationConfiguration.DirectImpersonationUrls ? json.ImpersonationConfiguration.DirectImpersonationUrls.map(url => new ImpersonationUrl(url.Name, url.Url)) : [],
                    ) : new ImpersonationConfiguration(),
                json.Groups ? json.Groups.map(group => new Group(group.GroupId, group.Name, group.Role)) : [],
                json.AuditLogConfiguration ? 
                    new AuditLogConfiguration(
                        json.AuditLogConfiguration.EnableAuditLogging ? getBooleanFromValue(json.AuditLogConfiguration.EnableAuditLogging) : false,
                        json.AuditLogConfiguration.EnableEditorAuditLogging ? getBooleanFromValue(json.AuditLogConfiguration.EnableEditorAuditLogging) : false,
                        json.AuditLogConfiguration.EnableAuditLogReports ? getBooleanFromValue(json.AuditLogConfiguration.EnableAuditLogReports) : false,
                        json.AuditLogConfiguration.LogsEndpoint ? json.AuditLogConfiguration.LogsEndpoint : "",
                        json.AuditLogConfiguration.FilteredEditorsEndpoint ? json.AuditLogConfiguration.FilteredEditorsEndpoint : "",
                        json.AuditLogConfiguration.ExportReportEndpoint ? json.AuditLogConfiguration.ExportReportEndpoint : "") 
                    : new AuditLogConfiguration(),
                json.FilterApis ? json.FilterApis.map((api, index) => 
                    new FilterApi(
                        index,
                        api.CorrelatedExtensionAttribute,
                        api.InputName, 
                        api.EndpointUrl)
                ) : []
            );
        }
        return new TenantConfig();
    }

    constructor(
        /*
            Below attributes are minimum required for organizational instance
        */
        public orgId: string = "",
        public privateConfiguration: boolean = false,
        public azureAdTenantId: string = "",
        public b2cDomainName: string = "",
        /*
            Below attributes are optional with defaults
        */
        public customUI: CustomUI = new CustomUI(),
        public customAttributes: Array<TenantAttribute> = [],
        public azureAttributeConfiguration: AzureAttributeConfiguration = new AzureAttributeConfiguration(),
        public passwordConfiguration: PasswordConfiguration = new PasswordConfiguration(),
        public impersonationConfiguration: ImpersonationConfiguration = new ImpersonationConfiguration(),
        public groups: Array<Group> = [],
        public auditLogConfiguration: AuditLogConfiguration = new AuditLogConfiguration(),
        public filterApis: Array<FilterApi> = []
    ) {        
    }
}