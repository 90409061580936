export enum OrganizationSize {
    Small = "Small",
    Large = "Large"
}

export function getOrganizationSizeFromString(size: string): OrganizationSize {
    switch (size) {
        case "Small": return OrganizationSize.Small;
        default: return OrganizationSize.Large;
    }
};