import { IIconProps } from "@fluentui/react";

export enum B2CIcon {
    Add = `Add`,
    Cancel = `Cancel`,
    CollapseUp = `ChevronUp`,
    Delete = `Delete`,
    EnabledRow = `StatusCircleCheckmark`,
    ExpandDown = `ChevronDown`,
    Filter = `Filter`,
    GoBack = `RevToggleKey`,
    Save = `Save`,
    PasswordField = `PasswordField`,
    Signin = `Signin`,
    SignOut = `SignOut`,
    Repair = `Repair`,
    ContactList = `ContactList`,
    AccountActivity = `AccountActivity`,
    Home = `Home`
}

export const getIconProps = (icon: B2CIcon): IIconProps => {
    return { iconName: icon };
}