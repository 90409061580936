import { CustomAttributeType, getCustomAttributeTypeFromString } from "./enums/CustomAttributeType";
import { ResetPasswordPolicy } from "./enums/ResetPasswordPolicy";
import { TenantConfig } from "./TenantConfig";

export interface IDropdownPermission {
    AttributeName: string;
    IsEnabled: boolean;
    CharacterLimit: number;
    ResetPasswordPolicy: string;
}

export class DropdownPermission {
    public static fromJson = (json?: IDropdownPermission) => {
        if (json) {
            return new DropdownPermission(
                json.AttributeName,
                json.IsEnabled,
                json.CharacterLimit,
                json.ResetPasswordPolicy ? json.ResetPasswordPolicy as ResetPasswordPolicy : undefined
            );
        }

        return new DropdownPermission();
    }
    constructor(
        public attributeName: string = "",
        public isEnabled: boolean = false,
        public characterLimit: number = NaN,
        public resetPasswordPolicy: ResetPasswordPolicy | undefined = undefined,
    ) {        
    }
}

export interface IDropdownOptionJson {
    ClientValue: string;
    ServerValue?: string;
    DropdownPermissions?: Array<IDropdownPermission>;
}

export class DropdownOption {
    public static fromJson = (json?: IDropdownOptionJson) => {
        if (json) {
            return new DropdownOption(
                json.ClientValue,
                json.ServerValue,
                json.DropdownPermissions ? json.DropdownPermissions.map(permission => DropdownPermission.fromJson(permission)) : []
            );
        }

        return new DropdownOption();
    }
    constructor(
        public clientValue: string = "",
        public serverValue: string = "",
        public dropdownPermissions: Array<DropdownPermission> = []
    ) {        
    }
}

export interface ITenantAttributeJson {
    AttributeName: string;
    ClientValue: string;
    AttributeType: string;
    ReadOnly: boolean;
    ReadOnlyOnCreate: boolean;
    DisableEnable: boolean;
    AttributeOptions?: Array<IDropdownOptionJson>;
}

export class TenantAttribute {
    public static fromJson = (json?: ITenantAttributeJson) => {
        if (json) {
            return new TenantAttribute(
                json.AttributeName,
                json.ClientValue,
                getCustomAttributeTypeFromString(json.AttributeType),
                json.ReadOnly,
                json.ReadOnlyOnCreate ? json.ReadOnlyOnCreate : false,
                json.DisableEnable ? json.DisableEnable : false,
                json.AttributeOptions ? json.AttributeOptions.map(option => DropdownOption.fromJson(option)) : []
            );
        }

        return new TenantAttribute();
    }

    constructor(
        public name: string = "",
        public clientValue: string = "",
        public type: CustomAttributeType = CustomAttributeType.String,
        public readOnly: boolean = true,
        public readOnlyOnCreate: boolean = false,
        public disableEnable: boolean = false,
        public options: Array<DropdownOption> = []
    ) {        
    }
}

export interface UserAttribute {
    name: string;
    value?: string | boolean | number | undefined;
    type: CustomAttributeType;
    enabled?: boolean;
    readOnlyOnCreate?: boolean;
    disableEnable?: boolean;
    readOnly?: boolean;    
}

export function userAttributeFromServerModel(attribute: UserAttribute, tenantConfig: TenantConfig): UserAttribute {
    let copiedAttribute = {...attribute};
    let tenantAttribute = tenantConfig.customAttributes.find(tAttribute => tAttribute.name === attribute.name);
    if (tenantAttribute) {
        switch (tenantAttribute.type) {
            case CustomAttributeType.Bool:
            case CustomAttributeType.Int:
            case CustomAttributeType.String:
                break;
            case CustomAttributeType.Dropdown:
                let tenantAttribute = tenantConfig.customAttributes.find(tAttribute => tAttribute.name === attribute.name);
                if (tenantAttribute) {
                    var option = tenantAttribute.options.find(x => x.serverValue === attribute.value);
                    if (option) {
                        copiedAttribute.value = option.clientValue;
                    }
                }
                copiedAttribute.type = CustomAttributeType.Dropdown;

        }
    }
    return copiedAttribute;
}

export function userAttributetoServerModel(attribute: UserAttribute, tenantConfig: TenantConfig): UserAttribute {
    let copiedAttribute = {...attribute};
    switch (copiedAttribute.type) {
        case CustomAttributeType.Bool:
        case CustomAttributeType.Int:
        case CustomAttributeType.String:
            break;
        case CustomAttributeType.Dropdown:
            let tenantAttribute = tenantConfig.customAttributes.find(tAttribute => tAttribute.name === attribute.name);
            if (tenantAttribute) {
                var option = tenantAttribute.options.find(x => x.clientValue === attribute.value);
                console.log(copiedAttribute.value);
                if (option && option.serverValue) {
                    copiedAttribute.value = option.serverValue;
                }
                console.log(copiedAttribute.value);
            }
            copiedAttribute.type = CustomAttributeType.String;

    }
    return copiedAttribute;
}