import { IPrimaryColorsJson, PrimaryColors } from "./PrimaryColors";
import { ISecondaryColorsJson, SecondaryColors } from "./SecondaryColors";

export interface IBrandingColorsJson {
    primary: IPrimaryColorsJson;
    secondary: ISecondaryColorsJson;
}

export class BrandingColors {
    public static fromJson = (json?: IBrandingColorsJson) => {
        if (json) {
            return new BrandingColors(
                json.primary ? PrimaryColors.fromJson(json.primary) : undefined,
                json.secondary ? SecondaryColors.fromJson(json.secondary) : undefined
            );
        }

        return new BrandingColors();
    }

    constructor(
        // Custom Client Colors:
        public primary: PrimaryColors | undefined = undefined,
        public secondary: SecondaryColors | undefined = undefined,
        // Standard Colors for all Clients:
        public link: string = `#0078d4`,
    ) {        
    }
}