import { createSlice } from '@reduxjs/toolkit';
import { TenantConfig } from '../../models/TenantConfig';
// set intial state for tenantConfig
interface TenantConfigState {
  loading: boolean;
  hasErrors: boolean;
  tenantConfig: TenantConfig;
}
export const initialState: TenantConfigState = {
  loading: false,
  hasErrors: false,
  tenantConfig: new TenantConfig(),
};

const tenantConfigSlice = createSlice({
  name: `tenantConfig`,
  initialState,
  reducers: {
    initializeGetTenantConfig: state => {
      state.loading = true;
    },
    getTenantConfigSuccess: (state, { payload }) => {
      state.tenantConfig = TenantConfig.fromJson(payload.isPrivateConfiguration, payload, payload.orgId);
      state.loading = false;
      state.hasErrors = false;
    },
    getTenantConfigFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { initializeGetTenantConfig, getTenantConfigSuccess, getTenantConfigFailure} = tenantConfigSlice.actions;
export const tenantConfigSelector: (any) => TenantConfigState = (state) => state.tenantConfig;
export default tenantConfigSlice.reducer;
