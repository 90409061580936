export enum UserManagerActivityType {
    ResetPassword = "ResetPassword",
    Update = "Update",
    Create = "Create",
    Delete = "Delete",
    Impersonate = "Impersonate",
}

export enum ActivityType {
    SignIn = "Sign In",
    PasswordReset = "Password Reset",
    Updated = "Updated",
    Created = "Created",
    Deleted = "Deleted",
    Other = "Other",
}

export function getActivityType(activityName: string): ActivityType | undefined {
    switch (activityName) {
        case `Created`:
        case `Add user`:
            return ActivityType.Created;
        case `Deleted`:
        case `Delete user`:
            return ActivityType.Deleted;
        case `Other`:
            return ActivityType.Other;        
        case `Password Reset`:
            return ActivityType.PasswordReset;
        case `Sign In`:
            return ActivityType.SignIn;
        case `Updated`:
        case `Update user`:
            return ActivityType.Updated;
        default: 
            return undefined;
    }
}