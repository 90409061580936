import * as React from 'react';
import styled from "styled-components";
import { TenantConfig } from '../../models/TenantConfig';

interface IncomingProps {
    maxWidth?: string;
    textColor?: string;
    imgPath: string;
    tenantConfig?: TenantConfig;
}
 
const FlexLabelStyled = styled.div`
 
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  max-width: 200px; 
`;

const Logo = ({...props }: IncomingProps) => { 
  let alt = props.tenantConfig ? props.tenantConfig.customUI.brandingText.assessmentTitle : "B2C Helpdesk"
    return (
      <FlexLabelStyled>
           {props.imgPath ? 
            (
              <img src={props.imgPath} height="35" alt={alt} />
            ) : (
              <svg height="35" width="35">
                <circle cx="17" cy="17" r="15" stroke="#ffffff" stroke-width="3" fill="#aaa" />
              </svg>
           )}
           
      </FlexLabelStyled>
    );
};
 
export default Logo;