export interface IBrandingLogosJson {
    large: string;
    small: string;
}

export class BrandingLogos {
    public static fromJson = (json?: IBrandingLogosJson) => {
        if (json) {
            return new BrandingLogos(
                json.large,
                json.small
            );
        }

        return new BrandingLogos();
    }

    constructor(
        public large: string = "",
        public small: string = "",
    ) {        
    }
}