import React, { ReactNode } from 'react';
import styled from "styled-components";

interface ErrorPageProps extends React.HTMLProps<HTMLDivElement> {
  title: string;
  children: ReactNode;
}

const ErrorStyled = styled.div`
 text-align:center; font-size:18px;
 display: block;
 padding: 0;
 margin: 30px;
`;
const ErrorDetails = styled.div`
 text-align: left;
 max-width: 600px;
 margin: 0 auto;
 pre {
   white-space: pre-wrap;
   overflow-wrap: break-word;
 }
`;

const ErrorPage = (props: ErrorPageProps) => {
  const { title, children } = props;

  return (
    <ErrorStyled>
      <h3>{title}</h3>
      <ErrorDetails>
        {children}
        <pre>
          Timestamp: {new Date().toString()}<br />
        </pre>
      </ErrorDetails>
    </ErrorStyled>
  );
};

export default ErrorPage;