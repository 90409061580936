export enum ResetPasswordPolicy {
    EventGridOnly = "EventGridOnly",
    RandomPassword = "RandomPassword",
    ManualPassword = "ManualPassword",
    ForceChangeRandomPasswordNextSignIn = "ForceChangeRandomPasswordNextSignIn",
    ForceChangeManualPasswordNextSignIn = "ForceChangeManualPasswordNextSignIn"
}

export function getResetPasswordPolicyFromString(size: string): ResetPasswordPolicy {
    switch (size) {
        case "EventGridOnly": return ResetPasswordPolicy.EventGridOnly;
        case "RandomPassword": return ResetPasswordPolicy.RandomPassword;
        case "ManualPassword": return ResetPasswordPolicy.ManualPassword;
        case "ForceChangeRandomPasswordNextSignIn": return ResetPasswordPolicy.ForceChangeRandomPasswordNextSignIn;
        case "ForceChangeManualPasswordNextSignIn": return ResetPasswordPolicy.ForceChangeManualPasswordNextSignIn;
        default: return ResetPasswordPolicy.ManualPassword;
    }
};