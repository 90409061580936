import * as React from 'react';
import { useLocation } from 'react-router-dom';
import ErrorPage from './pages/misc/ErrorPage';
 
function AzureLandingPage() {
  const { token } = useLocation();

  return (
    <ErrorPage title="Not yet implemented">
      <p>Azure Marketplace integration is still under development.</p>
      <pre>
        Token Data: {JSON.stringify(token)}<br />
        Location: {window.location.href}
      </pre>
    </ErrorPage>
  );
}

export default AzureLandingPage;