import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './normalize.css';
import Theme from "./components/shared/Theme";
import * as serviceWorker from './serviceWorker';
import AuthenticationWrapper from './components/AuthenticationWrapper';
import {configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {Provider as StoreProvider} from 'react-redux';
import rootReducer from './store/slices';
import DelayedLoader from './components/shared/DelayedLoader';
import TenantContext from './components/TenantContext';
import AzureLandingPage from './components/AzureLandingPage';

// const middleware = [...getDefaultMiddleware(), logger];
const middleware = [...getDefaultMiddleware()];
const store = configureStore({reducer: rootReducer, middleware});

ReactDOM.render(
  <Theme>
    <BrowserRouter>
      <StoreProvider store={store}>
        <TenantContext>
          <Switch>
            <Route path="/org/:data" component={AuthenticationWrapper} />
            <Route path="/account/azure" component={AzureLandingPage} />
            <Route path='*' 
              render={() => {
                // Display a loading animation for a few seconds to give the app time to load the organizational data (if available) before displaying the error below
                return (
                  <DelayedLoader 
                    delay={3000}
                    component={(
                      <>
                        <p>
                          If you have arrived at this page, it means that the link you followed was not correctly formatted for your organization.<br />
                          Please reach out to your organization's IT team to let them know about the problem, and include this link:
                        </p>
                        <pre>{window.location.href}</pre>
                        <p>Thanks!</p>
                      </>
                    )}
                  />
                );
              }}
            />   
          </Switch>
        </TenantContext>
      </StoreProvider>
    </BrowserRouter>
  </Theme>,
  document.getElementById(`root`)
);

// Enable offline functionality for deployment as a progressive web app
serviceWorker.unregister();

