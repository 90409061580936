import React, { useState } from 'react';
import { Nav, INavLink, INavStyles, INavLinkGroup, initializeIcons} from '@fluentui/react';
import { useRouteMatch , useHistory} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { tenantConfigSelector } from '../../../store/slices/tenantConfigReducer';
import { B2CIcon } from '../../../models/enums/B2CIcon';

initializeIcons();

function SideNavigation(props) {
    const [activeLink, updateActiveLink] = useState(`UsersKey`);
    const { url } = useRouteMatch();
    const { tenantConfig } = useSelector(tenantConfigSelector);

    const history = useHistory();
    const navStyles: Partial<INavStyles> = {
        root: {
            height: 350,
            boxSizing: `border-box`,
            border: `1px solid #eee`,
            overflowY: `auto`,
        },
    };
    const navLinkGroups: INavLinkGroup[] = [
        {
            links: [
                {
                    name: `Dashboard`,
                    url: `${url}/dashboard/`,
                    key: `DashboardKey`,
                    icon: B2CIcon.Home,
                    disabled: true
                },
                {
                    name: `Users`,
                    url: `${url}/users/`,
                    key: `UsersKey`,
                    icon: B2CIcon.ContactList,
                },
                {
                    name: `Audit Logs`,
                    url: `${url}/report/`,
                    key: `ReportKey`,
                    icon: B2CIcon.AccountActivity,
                    disabled: (tenantConfig.auditLogConfiguration.enableAuditLogging || tenantConfig.auditLogConfiguration.enableEditorAuditLogging) ? 
                                !tenantConfig.auditLogConfiguration.enableAuditLogReports ? true : false : true
                }   
            ],
        },
    ];

    const onLinkClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
        if (item && item.key && ev) {
            ev.preventDefault();
            updateActiveLink(item.key);
            history.push(`${item.url}`);
        }
    };

    return (
        <Nav
            onLinkClick={onLinkClick}
            selectedKey={activeLink}
            ariaLabel="Side Navigation"
            styles={navStyles}
            groups={navLinkGroups}
        />
    );
  }
  
  export default SideNavigation;
  