export enum FieldName {
    Impersonate = "Impersonate",
    ResetPassword = "ResetPassword",
    ResetPasswordMode = "ResetPasswordMode",
    DeleteUser = "DeleteUser",
    CreateUser = "CreateUser",
    SaveEdits = "SaveEdits",
    LastName = "LastName",
    FirstName = "FirstName",
    Email = "Email",
    Username = "Username",
    AccountEnabled = "AccountEnabled",
    CustomAttribute = "CustomAttribute"
}