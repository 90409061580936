export interface IBrandingTextJson {
    assessmentTitle: string;
    headerText: string;
    introText: string;
}

export class BrandingText {
    public static fromJson = (json?: IBrandingTextJson) => {
        if (json) {
            return new BrandingText(
                json.assessmentTitle,
                json.headerText,
                json.introText
            );
        }

        return new BrandingText();
    }

    constructor(
        public assessmentTitle: string = "",
        public headerText: string = "",
        public introText: string = "",
    ) {        
    }
}