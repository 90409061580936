import React from "react";

export interface IErrorBoundaryProps {
    fallback: any;
}

interface IErrorBoundaryState {
    hasError: boolean;
    error: JSX.Element | null;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    state = { hasError: false, error: null };
    static getDerivedStateFromError(error) {
      return {
        hasError: true,
        error
      };
    }
    
    render() {
      if (this.state.hasError) {
        return this.props.fallback;
      }
      return this.props.children;
    }
  }