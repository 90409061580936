import { DetailsList, IColumn, SelectionMode, CommandBar, ICommandBarItemProps, IconButton, SearchBox, DefaultButton, ISearchBoxStyles, MessageBar, MessageBarType, ICommandBarStyles, IButtonStyles, ScrollablePane, ScrollbarVisibility, Dropdown, IDropdownOption, TextField, DatePicker, Icon } from '@fluentui/react';
import { debounce } from 'debounce';
import {} from '@fluentui/react/lib/TextField';
import React from "react";
import * as msal from "@azure/msal-browser";
import { H1, H2 } from '../../shared/Typography';
import { Row, Col } from '../../layout/ResponsiveGrid';
import { exportReport, getFilteredEditors, getFilteredUsers, getUserManagerAccountActivity } from '../../../api/userApi';
import { msalConfig,loginRequest } from '../../../configurations/msalConfig';
import { IUser } from '../../../models/User';
import { TenantConfig } from '../../../models/TenantConfig';
import { B2CIcon } from '../../../models/enums/B2CIcon';
import { FilterContainter } from '../../layout/FilterContainter';
import { UserAccountActivity } from '../../../models/UserAccountActivity';
import { FilterCriteria, getDefaultFilterCriteria, isDefaultFilterCriteria } from '../../shared/FilterCriteria';
import ActivityLog from '../../shared/ActivityLog';
import { convertAccountActivity } from '../../../helpers/userHelpers';
import { UserManagerActivityType } from '../../../models/enums/ActivityType';
import './report.css';
import { getGroupAccessToken } from '../../../services/authenticationServices';
import { SharedColumnName } from '../../../models/enums/SharedColumnName';

interface IncomingProps {
    orgId: string;
    tenantConfig: TenantConfig;
    userRole: string;
}
interface notificationType {
  type: MessageBarType | undefined;
  message: string;
}
interface ReportState {
  columns: Array<IColumn>;
  allUsers: Array<IUser>;
  filterCriteria: FilterCriteria;
  filteredUsers: Array<IUser>;
  filterUpdated: boolean;
  totalFilteredUsers: number;
  currentPage: number;
  accessToken: string;
  notificationType: notificationType;
  userTenantId: string;
  userOrEditor?: IUser;
  userOrEditorActivity: Array<UserAccountActivity>;
  getActivity: boolean;
  exportReport: boolean;
  showEditors: boolean;
  activityTypeFilter: UserManagerActivityType | null;
  startDate: Date | undefined;
  endDate: Date | undefined;
  groupsAccessToken: string;
  successNotificationTimer: NodeJS.Timeout | undefined;
}

const searchBoxStyle: Partial<ISearchBoxStyles> = { 
  root: { 
    maxWidth: 300,
    background: `transparent`,
    border: `none`,
  } 
};

const iconButtonSyles: IButtonStyles = { 
  root: { 
    backgroundColor:  'rgb(255, 255, 255) !important', 
    marginRight: `20px !important`,
    marginLeft: '0px !important',
    borderBottom: '1px solid black !important',
    borderTop: '1px solid black !important' ,
    borderRight: '1px solid black !important'  }
};    

class Report extends React.Component<IncomingProps, ReportState>  {
  static commandBarStyles: ICommandBarStyles = {
    root:{  
      padding:0, 
      borderBottom: `1px solid #edebe9`,
      display: `flex`,
      flexDirection: `row`,
      flexFlow: `wrap`,
      height: `auto`,
      selectors: {
        '.ms-OverflowSet': { margin: '.5em 0' },
        '.ms-OverflowSet-item button': { height: 'auto', minWidth: 'auto' }
      }
    },
    secondarySet: {alignItems: `center`},
    primarySet: {alignItems: `center`}
  }
  
  private debounceGetFilteredUsers = debounce(
    (token: string, loginResponse?: msal.AuthenticationResult) => {
        try {
          let filterCriteria = {...this.state.filterCriteria, isDefault: isDefaultFilterCriteria(this.state.filterCriteria) };
          getFilteredUsers(this.props.orgId, filterCriteria, token, this.props.tenantConfig, this.state.groupsAccessToken).then(userListResponse => {
            if(userListResponse){
              if (userListResponse.status && userListResponse.status > 200) {
                throw new Error(userListResponse.message);
              }
              if (this.state.successNotificationTimer) {
                clearTimeout(this.state.successNotificationTimer);
              }
              let componentHolder = this;
              var matchingUsers: Array<IUser> = userListResponse.users;
              this.setState({
                filteredUsers: matchingUsers,
                filterUpdated: false,
                totalFilteredUsers: userListResponse.count,
                accessToken: token,
                userTenantId: loginResponse ? loginResponse.tenantId : this.state.userTenantId,
                successNotificationTimer: setTimeout(function() { 
                  if (componentHolder.state.notificationType.type === MessageBarType.success) {
                    componentHolder.setState({ notificationType: { type:undefined, message:``} });
                  }
                }, 3000),
                notificationType: filterCriteria.isDefault ? {type: undefined, message: ""} : {type:MessageBarType.success, message:`Found ${userListResponse.count} matching ${this.state.showEditors ? "editors" : "users"} with logged actions.`} 
              });
              }
            }).catch(err => {
              this.setState({ 
                notificationType: {type:MessageBarType.error, message:`${err.message}`}, 
                filterUpdated: false,
                userTenantId: loginResponse ? loginResponse.tenantId : this.state.userTenantId });
            });
        } catch {
            this.setState({
                notificationType: { type: MessageBarType.error, message: `Unexpected error when filtering users.` },
                filterUpdated: false,
                userTenantId: loginResponse ? loginResponse.tenantId : this.state.userTenantId
            });
        }
    },
    1000
  );

  private debounceGetFilteredEditors = debounce(
    (token: string, loginResponse?: msal.AuthenticationResult) => {
        try {
          let filterCriteria = {...this.state.filterCriteria, isDefault: isDefaultFilterCriteria(this.state.filterCriteria) };
          getFilteredEditors(this.props.orgId, filterCriteria, token, this.props.tenantConfig, this.state.groupsAccessToken).then(userListResponse => {
            if(userListResponse){
              if (userListResponse.status && userListResponse.status > 200) {
                throw new Error(userListResponse.message);
              }
              if (this.state.successNotificationTimer) {
                clearTimeout(this.state.successNotificationTimer);
              }
              let componentHolder = this;
              var matchingUsers: Array<IUser> = userListResponse.users;
              this.setState({
                filteredUsers: matchingUsers,
                filterUpdated: false,
                totalFilteredUsers: userListResponse.count,
                accessToken: token,
                userTenantId: loginResponse ? loginResponse.tenantId : this.state.userTenantId,
                successNotificationTimer: setTimeout(function() { 
                  if (componentHolder.state.notificationType.type === MessageBarType.success) {
                    componentHolder.setState({ notificationType: { type:undefined, message:``} });
                  }
                }, 3000),
                notificationType: filterCriteria.isDefault ? {type: undefined, message: ""} : {type:MessageBarType.success, message:`Found ${userListResponse.count} matching ${this.state.showEditors ? "editors" : "users"} with logged actions.`}  
              });
              }
            }).catch(err => {
              this.setState({ 
                notificationType: {type:MessageBarType.error, message:`${err.message}`}, 
                filterUpdated: false,
                userTenantId: loginResponse ? loginResponse.tenantId : this.state.userTenantId });
            });
        } catch {
            this.setState({
                notificationType: { type: MessageBarType.error, message: `Unexpected error when filtering editors.` },
                filterUpdated: false,
                userTenantId: loginResponse ? loginResponse.tenantId : this.state.userTenantId
            });
        }
    },
    1000
  );

  _didMount = false;
  
  constructor(props) {
    super(props);
    this.state = {      
      allUsers: [],
      columns: [
        {
          key: `${SharedColumnName.Name}Column`,
          name: SharedColumnName.Name,
          fieldName: `displayName`,
          minWidth: 150,
          maxWidth: 176,
          isRowHeader: true,
          data: `string`,
          isPadded: true,
        },  
        {
          key: `${SharedColumnName.Email}Column`,
          name: SharedColumnName.Email,
          fieldName: `email`,
          minWidth: 150,
          maxWidth: 176,
          isRowHeader: true,
          data: `string`,
          isPadded: true,
        } 
      ],
      filterCriteria: getDefaultFilterCriteria(),
      filteredUsers: [],
      groupsAccessToken: ``,
      filterUpdated: false,
      totalFilteredUsers: 0,
      currentPage: 1,
      accessToken: ``,
      notificationType: {type: undefined, message: ""},
      userTenantId: ``,
      userOrEditorActivity: [],
      userOrEditor: undefined,
      getActivity: false,
      exportReport: false,
      activityTypeFilter: null,
      startDate: undefined,
      endDate: undefined,
      successNotificationTimer: undefined,
      showEditors: !this.props.tenantConfig.auditLogConfiguration.enableAuditLogging && this.props.tenantConfig.auditLogConfiguration.enableEditorAuditLogging
    };
  }

  componentDidMount() {
    this._didMount = true;
    this.getTokenSilent();
    this.postRenderAdjustments();
    window.removeEventListener("resize", this.postRenderAdjustments);
    window.addEventListener("resize", this.postRenderAdjustments);
  }

  componentDidUpdate(prevProps: IncomingProps, prevState: ReportState) {
    if (this.props.tenantConfig.auditLogConfiguration.enableAuditLogging && this.state.filterUpdated && !this.state.showEditors && !isDefaultFilterCriteria(this.state.filterCriteria)) {
      this.debounceGetFilteredUsers(this.state.accessToken);
    } else if (this.props.tenantConfig.auditLogConfiguration.enableEditorAuditLogging && this.state.filterUpdated && this.state.showEditors && !isDefaultFilterCriteria(this.state.filterCriteria)) {
      this.debounceGetFilteredEditors(this.state.accessToken);
    }
    if (this.state.getActivity && this.state.userOrEditor && !isDefaultFilterCriteria(this.state.filterCriteria)) {
      getUserManagerAccountActivity(this.props.orgId, this.state.accessToken, this.state.userOrEditor.id, this.props.tenantConfig.auditLogConfiguration.logsEndpoint, this.state.groupsAccessToken).then(response => {
        if (response.status && response.status > 200) {
          throw new Error(response.message);
        }
        if (response  && this.state.userOrEditor) {
          let accountActivity: Array<UserAccountActivity> = convertAccountActivity(response, this.state.userOrEditor.id);
          this.setState({
            userOrEditorActivity: accountActivity,
            getActivity: false,
            notificationType: {type:MessageBarType.success, message:`Retrieved all activity.`}
          });
        }
      }).catch(err => {
        this.setState({
          notificationType: {type:MessageBarType.error, message:`${err.message}`},
          getActivity: false,
        });
      });
    }
    if ((this.props.tenantConfig.auditLogConfiguration.enableAuditLogging || this.props.tenantConfig.auditLogConfiguration.enableEditorAuditLogging)
        && this.props.tenantConfig.auditLogConfiguration.enableAuditLogReports && this.state.exportReport && this.state.userOrEditor
        && this.props.tenantConfig.auditLogConfiguration.exportReportEndpoint !== "") {
      exportReport(this.props.orgId, this.state.accessToken, this.state.userOrEditor.id, this.props.tenantConfig.auditLogConfiguration.exportReportEndpoint, this.state.groupsAccessToken).then(response => {
        if (response.status && response.status > 200) {
            throw new Error(response.message);
        }
        if (response && this.state.userOrEditor) {
            var link = document.createElement("a");
            link.download = response;
            link.href = response;
            link.click();
          
          this.setState({
            exportReport: false,
            notificationType: {type:MessageBarType.success, message:`Report exported to excel.`}
          });
        }
      }).catch(err => {
        this.setState({
          notificationType: {type:MessageBarType.error, message:`${err.message}`},
          exportReport: false,
        });
      });
    }
    this.postRenderAdjustments();
  }

  componentWillUnmount() {
    this._didMount = false; 
  }

  render() {
    let filteredUserList = this.state.filteredUsers;
    let authorized = this.props.tenantConfig.azureAdTenantId === this.state.userTenantId;

    // Set the day to 12 AM the next day for end date to include selected day.
    // Start date is fine as is because it is 12 AM the selected date and inclusive.
    let endDate = this.state.endDate ? new Date(this.state.endDate.getFullYear(), this.state.endDate.getMonth(), this.state.endDate.getDate() + 1) : "";
    let filteredActivity = this.state.userOrEditorActivity.filter(activity => 
      // Date filters
      (this.state.endDate ? endDate > activity.date : true) && 
      (this.state.startDate ? this.state.startDate <= activity.date : true) &&
      // Acivity filter
      (this.state.activityTypeFilter && activity && activity.activityType 
          ? activity.category.toString() === this.state.activityTypeFilter.toString() : true) 
    );
    var dropdownOptions: IDropdownOption [] = Object.keys(UserManagerActivityType).map(option => { 
      return { 
        text: option === UserManagerActivityType.ResetPassword ? "Reset Password" : option, 
        key: option
      } 
    });
    
    var usersString = this.state.showEditors ? "Viewing Editor" : "Viewing User";
    return ( !(this.props.tenantConfig.auditLogConfiguration.enableAuditLogging 
        || this.props.tenantConfig.auditLogConfiguration.enableEditorAuditLogging) 
      ? <Row><H1>{<Icon iconName={B2CIcon.AccountActivity} style={{ marginLeft: 5 }} />} Audit logging not enabled.</H1></Row>
      : this.props.tenantConfig.auditLogConfiguration.enableAuditLogReports ?
        <Row fullWidth>
          <Col xs={12} md={12}> 
            <Row fullWidth paddSmSides id={'pageHeader'}>
              <Col xs={12} md={12}>
                <H1>{<Icon iconName={B2CIcon.AccountActivity} style={{ marginLeft: 5 }} />} {usersString} Audit Logs</H1>
                <CommandBar
                  items={this.getLeftItems(this.props.userRole)}
                  ariaLabel="User List Command Buttons"
                  styles={Report.commandBarStyles}
                  farItems={this.getRightItems()}
                />
              </Col>
            </Row>
            { !this.state.userOrEditor && 
              <FilterContainter>
                <Row fullWidth>
                  <Col xs={12} md={3}>
                    <SearchBox
                      placeholder={`Exact Email or Username`}
                      value={this.state.filterCriteria.issuerAssignedId}
                      contentEditable={true}
                      onChange={(_, newValue) => this.updateIdentityFilter(newValue)}   
                      styles={searchBoxStyle}             
                    />
                  </Col>
                  {this.state.filterCriteria.issuerAssignedId !== '' ? <div/> :
                    <>
                      <Col xs={12} md={3}>
                        <SearchBox
                          placeholder={`Filter by Name`}
                          value={this.state.filterCriteria.keyword}
                          contentEditable={true}
                          onChange={(_, newValue) => this.updateUserNameFilter(newValue)}
                          styles={searchBoxStyle} />
                      </Col>
                    </> 
                  }
                </Row> 
              </FilterContainter> }
              { this.state.userOrEditor && 
              <FilterContainter>
                <Row fullWidth>
                  <Col xs={12} md={3}>
                    <Dropdown 
                      selectedKey={this.state.activityTypeFilter} 
                      options={dropdownOptions}
                      onRenderPlaceHolder={(item) => <TextField value={"Filter by Activity Type"} disabled={true} />}
                      onChange={(e,value) => { 
                        var activityTypeFilter = value ? value.key as UserManagerActivityType : null;
                        this.setState({activityTypeFilter}) }
                      }
                    />
                  </Col>
                  <IconButton 
                      iconProps={{ iconName: B2CIcon.Cancel }} 
                      onClick={() => this.setState({activityTypeFilter: null})}
                      styles={iconButtonSyles}
                    />
                  <Col xs={12} md={3}>
                    <DatePicker
                      isRequired={false}
                      allowTextInput={true}
                      placeholder={"Start Date"}
                      textField={ {
                        styles: {
                          fieldGroup: {
                            backgroundColor: this.state.startDate ? 'rgb(255, 255, 255) !important' : 'rgb(243, 242, 241) !important',
                          }
                        }
                      }}
                      value={this.state.startDate}
                      formatDate={(d) => d ? d.toLocaleDateString("en-US") : "Start Date"}
                      onSelectDate={(d) => this.setState({startDate: d ? d : undefined})}
                    />
                  </Col>
                  <IconButton 
                      iconProps={{ iconName: B2CIcon.Cancel }} 
                      onClick={() => this.setState({startDate: undefined})}
                      styles={iconButtonSyles}
                    />
                  <Col xs={12} md={3}>
                    <DatePicker
                      isRequired={false}
                      allowTextInput={true}
                      placeholder={"End Date"}
                      textField={ {
                        styles: {
                          fieldGroup: {
                            backgroundColor: this.state.endDate ? 'rgb(255, 255, 255) !important' : 'rgb(243, 242, 241) !important',
                          }
                        }
                      }}
                      value={this.state.endDate}
                      formatDate={(d) => d ? d.toLocaleDateString("en-US") : "End Date"}
                      onSelectDate={(d) => this.setState({endDate: d ? d : undefined})}
                    />
                  </Col>
                  <IconButton 
                      iconProps={{ iconName: B2CIcon.Cancel }} 
                      onClick={() => this.setState({endDate: undefined})}
                      styles={iconButtonSyles}
                    />
                </Row> 
              </FilterContainter> }
            <Row fullWidth>
              <Col xs={12} md={12} >
                {(this.state.notificationType.type === MessageBarType.success) && this.successNotification()}
                {(this.state.notificationType.type === MessageBarType.error) && this.errorNotification()}
                {(this.state.notificationType.type === MessageBarType.info) && this.loadingNotification()}
              </Col>
            </Row>
            { !this.state.userOrEditor && 
              <Row fullWidth paddSmSides>
                <Col xs={12} md={12} >
                  <ScrollablePane 
                    scrollbarVisibility={ScrollbarVisibility.auto} 
                    id={'userListContainer'}
                    style={{ minHeight: 100 }}
                  >
                    <DetailsList                
                      items={isDefaultFilterCriteria(this.state.filterCriteria) ? [] : filteredUserList}
                      columns={authorized ? this.state.columns : []}
                      selectionMode={SelectionMode.single}
                      isHeaderVisible
                      styles={{
                        root:{ padding:0,},
                        headerWrapper: {padding: 0, margin: 0},
                        contentWrapper: {padding: 0, margin: 0},
                      }}
                      onRenderItemColumn={(item,index,column) => this.renderItemColumn(item,index,column)}                
                    />
                  </ScrollablePane>                            
                </Col>
              </Row> }
            { this.state.userOrEditor &&
              <Row fullWidth paddSmSides>
                <Col xs={12} md={6}>
                  <H2 style={{ marginBottom: 0 }}>{this.state.userOrEditor.email}</H2>
                    {this.state.userOrEditor && this.state.accessToken ? 
                        this.props.tenantConfig.auditLogConfiguration.enableAuditLogging 
                          || this.props.tenantConfig.auditLogConfiguration.enableEditorAuditLogging ? (
                          <ActivityLog 
                            userActivities={filteredActivity.filter(x => x.advancedUserManagerActivity)} 
                            correspondingActivities={new Array<UserAccountActivity>()}
                            borderWidth={'0 0 0 1px'}
                            tenantConfig={this.props.tenantConfig}
                          />) 
                        : (<></>)
                    : (<></>)}
                </Col>
              </Row> }
          </Col>
        </Row> : <Row><H1>Audit Reports are not enabled.</H1></Row>
    );
  }

  //#region Get Data
  async getTokenSilent() {
    const silentRequest: any = {
      scopes: loginRequest.scopes,
      authority: `https://login.microsoftonline.com/${this.props.tenantConfig.azureAdTenantId}`
    };
    const msalInstance = new msal.PublicClientApplication(msalConfig(this.props.tenantConfig.azureAdTenantId));
    if (msalInstance.getAllAccounts() && msalInstance.getAllAccounts().length > 0) {
      silentRequest.loginHint = msalInstance.getAllAccounts()[0].username;
    }
    const loginResponse = await msalInstance.ssoSilent(silentRequest);
    
    // Check if the groups claim was too large to return to MSAL on client,
    // if so then send groups access token back for API to resolve groups.
    let groupsAccessToken = await getGroupAccessToken(loginResponse, this.props.tenantConfig.azureAdTenantId);

    this.setState({ 
        accessToken: loginResponse.accessToken,
        groupsAccessToken, 
        filterUpdated: false,
        totalFilteredUsers: 0,
        userTenantId: loginResponse ? loginResponse.tenantId : this.state.userTenantId,});
  }

  //#endregion
  renderItemColumn = (item: IUser, index: number | undefined, column: IColumn | undefined) => {
    if(column){
      const fieldContent = item[column.fieldName as keyof IUser] as string;
      switch (column.key) {
        case `${SharedColumnName.Name}Column`:
          return <button onClick={(x) => this.selectUser(item)} style={{color:`${this.props.tenantConfig.customUI.brandingColors.link}`}}>{fieldContent}</button>;
        case `${SharedColumnName.Email}Column`:
          return <span>{item.email}</span>
        default:
          return <span>{fieldContent}</span>;
      }
    }
   
  }
  private selectUser(user: IUser) {
    var userOrEditor = this.state.showEditors ? 'editor' : 'user';
    this.setState({
      getActivity: true,
      userOrEditor: user,
      notificationType: {type:MessageBarType.info, message:`Loading ${userOrEditor} activity...`}
    });
  }
  private loadingNotification = () => (
    <MessageBar messageBarType={MessageBarType.info} onDismiss={() => this.closeMessageBar()}>
       {this.state.notificationType.message}
    </MessageBar>
  );
  private errorNotification = () => (
    <MessageBar messageBarType={MessageBarType.severeWarning} onDismiss={() => this.closeMessageBar()}>
       {this.state.notificationType.message}
    </MessageBar>
  );
  private successNotification = () => (
    <MessageBar messageBarType={MessageBarType.success} onDismiss={() => this.closeMessageBar()}>
      {this.state.notificationType.message}
    </MessageBar>
  );

  private closeMessageBar = (): void => {
    this.setState({ notificationType: { type:undefined, message:``} })
  };
  private getLeftItems = (userRole): ICommandBarItemProps[] => {
    return [
      {
        key: `current results`,
        commandBarButtonAs: () => {
          let navigationButtonStyles: IButtonStyles = {
            root: {
              padding: '.25em .75em', 
              borderColor: `${this.props.tenantConfig.customUI.brandingColors.link}`,              
              selectors: {
                '&:hover:': { backgroundColor: `${this.props.tenantConfig.customUI.brandingColors.secondary?.defaultColor}`}
              }
            }
          }
          var usersString = this.state.showEditors ? "Search Users" : "Viewing Users";
          var editorsString = this.state.showEditors ? "Viewing Editors" : "Search Editors";
          var usersButton = this.props.tenantConfig.auditLogConfiguration.enableAuditLogging ? ( 
            <DefaultButton 
              onClick={() => this.setState({
                showEditors: false, 
                filteredUsers: [], 
                filterUpdated: true, 
                totalFilteredUsers: 0,
                notificationType: isDefaultFilterCriteria(this.state.filterCriteria) ? {type: undefined, message: ""} : {type:MessageBarType.info, message:`Loading users...`}    
              })}
              styles={navigationButtonStyles}
              style={this.props.tenantConfig.auditLogConfiguration.enableEditorAuditLogging ? { marginRight: '.5em' } : undefined}
              disabled={!this.state.showEditors}
            > 
            {usersString}
            </DefaultButton>) : <div/>;

          var editorsButton = this.props.tenantConfig.auditLogConfiguration.enableEditorAuditLogging ? ( 
            <DefaultButton
                onClick={() => this.setState({
                  showEditors: true, 
                  filteredUsers: [], 
                  filterUpdated: true, 
                  totalFilteredUsers: 0,
                  notificationType: isDefaultFilterCriteria(this.state.filterCriteria) ? {type: undefined, message: ""} : {type:MessageBarType.info, message:`Loading editors...`}   
                })}
                styles={navigationButtonStyles}
                style={this.props.tenantConfig.auditLogConfiguration.enableAuditLogging ? { marginRight: '.5em' } : undefined}
                disabled={this.state.showEditors}
              >
                {editorsString}
              </DefaultButton>   ) : <div/>;
          return !this.state.userOrEditor ? (
            <div>              
              {this.state.showEditors ? usersButton : (<div/>)}
              {!this.state.showEditors ? editorsButton : (<div/>)}            
            </div>
          ) : <div />
        }
      },
      {
      key: `Export`,
      commandBarButtonAs: () => {
        let navigationButtonStyles: IButtonStyles = {
          root: {
            padding: '.25em .75em', 
            borderColor: `${this.props.tenantConfig.customUI.brandingColors.link}`,              
            selectors: {
              '&:hover:': { backgroundColor: `${this.props.tenantConfig.customUI.brandingColors.secondary?.defaultColor}`}
            }
          }
        }
        
        return ( this.state.userOrEditor && this.state.userOrEditorActivity.length > 0 
            && this.props.tenantConfig.auditLogConfiguration.exportReportEndpoint  !== "" 
            && this.props.tenantConfig.auditLogConfiguration.enableAuditLogReports ?
          <div>              
            <DefaultButton
              onClick={() => this.exportReport()}
              styles={navigationButtonStyles}
            >
              Export Full Report
            </DefaultButton>              
          </div> : <div/>
        ) 
      }
    }];
  }

  private getRightItems = (): ICommandBarItemProps[] => {
    let throttledIndicator = this.state.totalFilteredUsers >= 100 ? "+" : "";
    let firstValue = this.state.filteredUsers.length > 0 ? 1 : 0;
    let pageSize = 10;
    let lastShown = ((this.state.currentPage - 1) * pageSize) + 
        this.state.filteredUsers.length > this.state.totalFilteredUsers ? this.state.totalFilteredUsers 
          : ((this.state.currentPage - 1) * pageSize) + this.state.filteredUsers.length;
    return [
      {
        key: `current results`,
        commandBarButtonAs: () => {
          let navigationButtonStyles: IButtonStyles = {
            root: {
              padding: '.25em .75em', 
              borderColor: `${this.props.tenantConfig.customUI.brandingColors.link}`,              
              selectors: {
                '&:hover:': { backgroundColor: `${this.props.tenantConfig.customUI.brandingColors.secondary?.defaultColor}`}
              }
            }
          }
          
          return !this.state.userOrEditor && (this.state.totalFilteredUsers > pageSize) ? (
            <div>              
              <DefaultButton 
                onClick={() => this.changePage('back')}
                styles={navigationButtonStyles}
                style={{ marginRight: '.5em' }}
                disabled={this.state.currentPage < 2}
              >
                Back
              </DefaultButton>
              <DefaultButton
                onClick={() => this.changePage('next')}
                styles={navigationButtonStyles}
                disabled={lastShown >= this.state.totalFilteredUsers }
              >
                Next
              </DefaultButton>              
            </div>
          ) : <div />
        }
      },
      {
        key: 'results',
        commandBarButtonAs: () => ( !this.state.userOrEditor ?
          <p style={{ margin: '0 0 0 .5em', minWidth: '200px', textAlign: 'center' }}>
            {!isDefaultFilterCriteria(this.state.filterCriteria) ? `${((this.state.currentPage - 1) * pageSize) + firstValue} to ${lastShown} 
              of ${this.state.totalFilteredUsers}${throttledIndicator} Results` : `Apply filters to see results`}
          </p> : <div/>
        )
      },
      {
        key: `filter`,
        commandBarButtonAs: () => ( !isDefaultFilterCriteria(this.state.filterCriteria) ?
          <IconButton 
            iconProps={{ iconName: B2CIcon.Cancel }} 
            onClick={() => this.toggleFilters()}
            styles={{ 
              root: { backgroundColor:  'transparent !important', }
            }}
          /> : <div/>
        )
      }
    ];
  }

  private exportReport() {
    this.setState({
      exportReport: true,
      notificationType: {type:MessageBarType.info, message:`Exporting report to excel...`},
    });
  }
  private changePage(direction: string) {
    
    if (direction === 'back') {      
      this.setState({
        currentPage: this.state.currentPage - 1,
        filterCriteria: {...this.state.filterCriteria, pageNumber: this.state.currentPage - 1},
        filterUpdated: true
      });
    } else if (direction === 'next') {
      let currentCriteria = {...this.state.filterCriteria, pageNumber: this.state.currentPage + 1};
      this.setState({
        currentPage: this.state.currentPage + 1,
        filterCriteria: currentCriteria,
        filterUpdated: true
      });
    }
  }

  private toggleFilters = (): void => {
    this.setState({ 
      filterCriteria: getDefaultFilterCriteria(),
      filterUpdated: true,
      totalFilteredUsers: 0,
      currentPage: 1,
      userOrEditor: undefined,
      userOrEditorActivity: [],
      activityTypeFilter: null,
      startDate: undefined,
      endDate: undefined
    });
  }

  private postRenderAdjustments = () => {
    // Grab all of the elements we'll want to either use as a reference or make adjustments to
    let appHeader = document.querySelector('Header');
    let pageHeader = document.querySelector('#pageHeader');
    let userListContainer = document.querySelector('#userListContainer');
    let userListHeader = document.querySelector('#userListContainer .ms-DetailsList-headerWrapper');
    let userListRecords = document.querySelector('#userListContainer .ms-DetailsList-contentWrapper');
    let viewPort = document.querySelector('#userListContainer .ms-Viewport');
    let detailsList = document.querySelector('#userListContainer .ms-DetailsList');

    // If they all exist, proceed with resizing updates
    if (appHeader && pageHeader && userListContainer && viewPort && detailsList && userListHeader && userListRecords) {  
      viewPort.setAttribute(`style`, `height: 100%`);
      detailsList.setAttribute(`style`, `height: 100%`);

      let maxContainerHeight = window.innerHeight - appHeader.clientHeight - pageHeader.clientHeight - 10; // 10 is to provide a 10px margin between the bottom of the results & the bottom of the browser window      
      let filterWrapper = document.querySelector('#filterWrapper');
      if (filterWrapper) {
        maxContainerHeight = maxContainerHeight - filterWrapper.clientHeight;
      }
      let defaultContainerHeight = userListHeader.clientHeight + userListRecords.clientHeight + 17; // 17 is the height of a horizontal scrollbar
      let containerHeight = maxContainerHeight < defaultContainerHeight ? maxContainerHeight : defaultContainerHeight;
      userListContainer.setAttribute(`style`, `height: ${containerHeight}px`);
    }
  }
  //#endregion
 
  //#region User Filtering
  updateUserNameFilter = (userName?: string) => {
    var userOrEditor = this.state.showEditors ? 'editors' : 'users';
    let updatedFilterCriteria = {...this.state.filterCriteria};
    updatedFilterCriteria.keyword = userName ? userName : '';
    this.setState({
      filterCriteria: {...updatedFilterCriteria, pageNumber: 1},
      filterUpdated: true,
      totalFilteredUsers: 0,
      currentPage: 1,
      notificationType: isDefaultFilterCriteria(updatedFilterCriteria) ? {type: undefined, message: ""} : {type:MessageBarType.info, message:`Loading ${userOrEditor}...`}  
    });
  }
  updateIdentityFilter = (userName?: string) => {
    var userOrEditor = this.state.showEditors ? 'editors' : 'users';
    let updatedFilterCriteria = {...this.state.filterCriteria};
    updatedFilterCriteria.issuerAssignedId = userName ? userName : '';
    this.setState({
      filterCriteria: {...updatedFilterCriteria, pageNumber: 1},
      filterUpdated: true,
      totalFilteredUsers: 0,
      currentPage: 1,
      notificationType: isDefaultFilterCriteria(updatedFilterCriteria) ? {type: undefined, message: ""} : {type:MessageBarType.info, message:`Loading ${userOrEditor}...`}    
    });
  }
  //#endregion
}

export default Report;
 