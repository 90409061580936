import { combineReducers } from 'redux';
import tenantConfigReducer from './tenantConfigReducer';
import usersReducer from './usersReducer';
import authenticatedReducer from './authentication';
// house and combine all reducers
const rootReducer = combineReducers({
  authenticatedUser: authenticatedReducer,
  users: usersReducer,
  tenantConfig: tenantConfigReducer,
});

export default rootReducer;
