import { BrowserCacheLocation, RedirectRequest } from "@azure/msal-browser";
import { redirect } from "./config";

// turn into function, pull in azureAdTenantId
export const msalConfig = (azureAdTenantId) => {
        return {
            auth: {
                clientId: `76452528-5cb2-453f-8103-7fc1a76b7c63`,
                authority: `https://login.microsoftonline.com/` + azureAdTenantId,
                // For testing Netrix
                // authority: `https://login.microsoftonline.com` + `/` + `f224c3c9-5f00-44e8-babd-133e9cd928d1`,
                // For testing ALAS
                // authority: `https://login.microsoftonline.com` + `/` + `3ffcd03b-5b74-4ca6-a7ed-a0d9b89da6b7`,

                redirectUri: `${redirect}`,

            },
            cache: {
                cacheLocation: BrowserCacheLocation.SessionStorage,
                storeAuthStateInCookie: false, 
            }
        };
    }
 
export const loginRequest: RedirectRequest = {
 scopes: [`openid`, `offline_access`, `api://76452528-5cb2-453f-8103-7fc1a76b7c63/B2C_Administration`]
};  
 