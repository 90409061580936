import React from 'react';
import styled from "styled-components";

type LoaderProps = {
  loadingMessage: string;
}

const LoaderStyled = styled.div`
 text-align:center; font-size:14px;
 display: block;
 width: 30%;
 padding: 40px;
 min-width: 320px;
 margin: 30px auto 0;
`;

const LoadingIcon = styled.div`display:block;position:relative;width:180px;height:180px;margin: 10% auto;
& div{position:absolute;border:6px solid #C0C0C0;opacity:1;border-radius:50%;animation:lds-ripple 2s cubic-bezier(0,.2,.8,1) infinite}
& div:nth-child(2){animation-delay:-.5s}@keyframes lds-ripple{0%{top:90px;left:90px;width:0;height:0;opacity:1}100%{top:0;left:0;width:172px;height:172px;opacity:0}}`;

function Loader(props: LoaderProps) {
  const { loadingMessage } = props;
  return (
    <LoaderStyled>
      <LoadingIcon><div></div><div></div></LoadingIcon>
      <h1>{loadingMessage}</h1>
    </LoaderStyled>
  );
};

export default Loader;