import styled from "styled-components";
 
interface TextProps {
    type?: string;
    id?: string; 
    placeholder?: string;  
}

interface RadioProps {
    type?: string;
    id?: string; 
    name?: string;
    value?:string  
}
 
export const H1 = styled.h1`
font-weight: 400;font-size: 20px; 
    padding: 0px 0px;color: ${({ theme: { colors } }) => colors.greys.xdark};
`;
export const H1Status = styled.h1`
    padding: 0px 40px 0px; 
`;

export const H2 = styled.h2`
    font-weight: 400;font-size: 20px; border-bottom:1px solid ${({ theme: { colors } }) => colors.greys.xdark};
    padding: 0 0 20px;  color: ${({ theme: { colors } }) => colors.greys.medium};margin: 10px 5px;
`;
export const H2Status = styled.h2`
    font-weight: 400;font-size: 20px; 
    border-top:1px solid ${({ theme: { colors } }) => colors.greys.xdark};
    font-size: 26px;
    line-height:36px;
    padding: 20px 5% 20px;
    margin: 0 60px;
    
    text-align:center;
    white-space: pre-wrap;
    @media ${({ theme: { deviceSizes } }) => deviceSizes.minMobile} { 
        margin: 0 20px 60px;font-size: 22px; line-height:32px;word-wrap: break-word;
    }
    @media ${({ theme: { deviceSizes } }) => deviceSizes.minTablet} { 
        margin: 0 60px 60px;font-size: 26px; line-height:36px;word-wrap: normal;
    }
`;
export const H3 = styled.h3`
    padding: 30px 40px;
`;
export const H4 = styled.h4`
    padding: 30px 40px;
`;
export const H5 = styled.h5`
    font-family: 'Roboto', sans-serif; text-align:center;
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    margin: 15px 0;
    color: ${({ theme: { colors } }) => colors.greys.medium}; 

`;
export const H6 = styled.h6`
    font-family: 'Roboto', sans-serif; text-align:center;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
    color: ${({ theme: { colors } }) => colors.greys.medium}; 
`;

export const H6Status = styled.h6`
    font-family: 'Roboto', sans-serif; text-align:center;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0; 
`;

export const FormControl = styled.div`
    margin-bottom: 10px;
    padding-bottom: 20px;
    position: relative;

    border-bottom:1px solid ${({ theme: { colors } }) => colors.greys.xxlight}; 

`;
 
export const TextField = styled.input<TextProps>`
    border: 2px solid #f0f0f0;
    border-radius: 4px;
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 14px; 
`;
 