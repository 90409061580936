import React, { ReactNode } from 'react';
import Loader from './Loader';

type OrganizationLoaderProps = {
  loadingMessage: string;
  configuration?: any;
  error?: JSX.Element;
  children?: ReactNode;
}

function OrganizationLoader(props: OrganizationLoaderProps) {
  const { loadingMessage, configuration, error, children } = props;
  return (
    <React.Fragment>
      {(!configuration && !error) ? (
        <Loader loadingMessage={loadingMessage} />
      ) : configuration && !error ? children : error }
    </React.Fragment>
  );
};

export default OrganizationLoader;