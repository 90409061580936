import { createSlice } from '@reduxjs/toolkit';
// set intial state for users
export const initialState = {
  loading: false,
  hasErrors: false,
  accessToken: ``,
  userRole: ``,
  groupsAccessToken: ``
};

const authenticatedUserSlice = createSlice({
  name: `users`,
  initialState,
  reducers: {
    initializeGetAuthenticatedUser: state => {
      state.loading = true;
    },
    getAuthenticatedUserSuccess: (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.groupsAccessToken = payload.groupsAccessToken;
      state.userRole = payload.account.idTokenClaims.roles ? payload.account.idTokenClaims.roles[0] : ``;
      state.loading = false;
      state.hasErrors = false;
    },
    getAuthenticatedUserFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { initializeGetAuthenticatedUser, getAuthenticatedUserSuccess, getAuthenticatedUserFailure} = authenticatedUserSlice.actions;
export const authenticatedUserSelector = state => state.authenticatedUser;
export default authenticatedUserSlice.reducer;
