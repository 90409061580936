
let authRedirect;
let apiBaseURL;
let azureStorageURL;
const hostname = window && window.location && window.location.hostname;
// Config for localhost
if (hostname === `localhost`) {
    azureStorageURL = `https://localhost:44347`;
    authRedirect = `http://localhost:3000`;
    apiBaseURL = `https://localhost:44347`; // Local Server
    // apiBaseURL = `https://aum-test.netrixllc.com`; // Test Server
// TODO: Phase out test.b2chelpdesk.app domain
} else if (hostname === `test.b2chelpdesk.app`) {
    // Test environment config
    azureStorageURL = `https://test.b2chelpdesk.app`;
    authRedirect = `https://test.b2chelpdesk.app`;
    apiBaseURL = `https://test.b2chelpdesk.app`; // Test Server
// TODO: Phase out www.b2chelpdesk.app domain
} else if (hostname === 'www.b2chelpdesk.app') {
    // Production environment config
    azureStorageURL = `https://www.b2chelpdesk.app`;
    authRedirect = `https://www.b2chelpdesk.app`;
    apiBaseURL = `https://www.b2chelpdesk.app`; // Prod Server
} else if (hostname === `aum-test.netrixllc.com`) {
    // Test environment config
    azureStorageURL = `https://aum-test.netrixllc.com`;
    authRedirect = `https://aum-test.netrixllc.com`;
    apiBaseURL = `https://aum-test.netrixllc.com`; // Test Server
} else if (hostname === 'aum.netrixllc.com') {
    // Production environment config
    azureStorageURL = `https://aum.netrixllc.com`;
    authRedirect = `https://aum.netrixllc.com`;
    apiBaseURL = `https://aum.netrixllc.com`; // Prod Server
} else {
  // eslint-disable-next-line no-throw-literal
  throw `Environment not implemented: ` + hostname;
}

export const HOST_NAME = hostname;
export const redirect = authRedirect;
export const storageURL = azureStorageURL;
export const apiUrl = apiBaseURL;
