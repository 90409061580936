import React from 'react';
import { ShimmerElementsGroup, ShimmerElementType, Shimmer, Fabric, mergeStyles } from '@fluentui/react';
const wrapperStyle = { display: 'flex' }; 

const getCustomElementsExampleThree = (): JSX.Element => {
    return (
      <div style={wrapperStyle}>
        <ShimmerElementsGroup
          width={'90px'}
          shimmerElements={[
            { type: ShimmerElementType.line, height: 80, width: 80 },
            { type: ShimmerElementType.gap, width: 10, height: 80 },
          ]}
        />
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          <ShimmerElementsGroup
            flexWrap
            width={'100%'}
            shimmerElements={[
              { type: ShimmerElementType.line, width: '90%', height: 10 },
              { type: ShimmerElementType.gap, width: '10%', height: 20 },
              { type: ShimmerElementType.line, width: '100%', height: 10 },
            ]}
          />
          <ShimmerElementsGroup
            flexWrap
            width={'100%'}
            shimmerElements={[
              { type: ShimmerElementType.line, width: '80%', height: 10, verticalAlign: 'bottom' },
              { type: ShimmerElementType.gap, width: '20%', height: 20 },
              { type: ShimmerElementType.line, width: '40%', height: 10, verticalAlign: 'bottom' },
              { type: ShimmerElementType.gap, width: '2%', height: 20 },
              { type: ShimmerElementType.line, width: '58%', height: 10, verticalAlign: 'bottom' },
            ]}
          />
        </div>
      </div>
    );
  };
  const wrapperClass = mergeStyles({
    padding: 2,
    selectors: {
      '& > .ms-Shimmer-container': {
        margin: '10px 0',
      },
    },
  });
  export const ShimmerStylingExample: React.FunctionComponent = () => (
    <Fabric className={wrapperClass}>
      <Shimmer customElementsGroup={getCustomElementsExampleThree()} width="90%" />
    </Fabric>
  );
  
