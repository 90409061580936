import React, { useEffect } from "react";
import ErrorPage from './pages/misc/ErrorPage';
import { fetchOrganizationConfiguration } from '../api/organizationApi';
import { MsalProvider } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { tenantConfigSelector } from "../store/slices/tenantConfigReducer";
import OrganizationLoader from "./shared/OrganizationLoader";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../configurations/msalConfig";

const instance = new PublicClientApplication(msalConfig(`common`))

const TenantContext = ({ children }) => {
    const dispatch = useDispatch();
    const { tenantConfig, hasErrors: tenantConfigHasErrors } = useSelector(tenantConfigSelector);
    var orgId = ''
    var redirect = false;
    if(window && window.location && window.location.href) {
        var urlPath = window.location.href.split('/');
        var orgIndex = urlPath.indexOf('org');
        if (orgIndex > -1 && orgIndex + 1 <= urlPath.length && urlPath[orgIndex + 1] !== ``) {
            orgId = urlPath[orgIndex + 1];
        } else {
            var hostIndex = urlPath.indexOf(window.location.host);
            if (hostIndex + 1 <= urlPath.length && (urlPath[hostIndex + 1].split('=')[0] === '#code')) {
                redirect = true;
            }
        }
    }
        useEffect(() => {    
        dispatch(fetchOrganizationConfiguration(orgId, redirect));
    }, [dispatch, orgId]);
    
    return (
        <OrganizationLoader
            loadingMessage="Loading organization configuration"
            configuration={tenantConfig}

            error={tenantConfigHasErrors ? (
                <ErrorPage title="Could not load organization configuration.">
                <p>Please contact your IT department and provide them with the following information:</p>
                <pre>
                    Tenant ID: {orgId}<br />
                    Location: {window.location.href}<br />
                    {/* Response Status Code: {reason?.status}<br />
                    Response: {JSON.stringify(reason)} */}
                </pre>
                </ErrorPage>
            ) : undefined}
            >
            {(tenantConfig.azureAdTenantId || redirect) && 
                <MsalProvider instance={instance}>{children}</MsalProvider>
            }
        </OrganizationLoader>
    );
}

export default TenantContext;