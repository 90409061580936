export enum Bool {
    True = "True",
    False = "False",
}

export function getBooleanFromValue(value: string | number | boolean | Bool | null | undefined): boolean {
    switch (value) {
        case true:
        case Bool.True:
        case "TRUE":
        case "True":
        case "true":
        case "YES":
        case "Yes":
        case "yes":
        case "ON":
        case "On":
        case "on":
        case "1":
        case 1:
            return true;
        case false:
        case Bool.False:
        case "FALSE":
        case "False":
        case "false":
        case "NO":
        case "No":
        case "no":
        case "OFF":
        case "Off":
        case "off":
        case "0":
        case 0:
            return false;
        default:
            return value ? true : false;
    }
}