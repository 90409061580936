import styled from "styled-components";
import {  IStackTokens  } from '@fluentui/react';
import { smPaddAmt } from "../shared/Theme";
interface ColsProps {
    xs: number;
    md: number;
    lg?: number;
    padding?:string;
    displayFlex?: boolean;
    filled?: boolean;
    paddSmLeft?: boolean;
    paddSmRight?: boolean;
}

interface RowProps {
  centerContent?: boolean;
  fullWidth?: boolean;
  grow?:boolean;
  filled?: boolean;
  paddSm?: boolean;
  paddSmSides?: boolean;
}
  
export const Row = styled.div<RowProps>`
  display: flex; width: 100%; flex-wrap:wrap; box-sizing:border-box;justify-content: flex-start;
  margin: 0 auto;
  background: ${ props => props.filled ? `#fff` : `none`};
  max-width:  ${ props => props.fullWidth ? `100%` : `1200px`};
  justify-content:  ${ props => props.centerContent ? `center` : `flex-start`}; 

  flex-grow: ${(props: any) => props.grow ? 3 : `unset`};
  padding:  ${ props => props.paddSm ? `${smPaddAmt}px 20px` : ``};
  ${ props => props.paddSmSides ? `padding: 0px 20px` : ``};

`;

export const Col = styled.div<ColsProps>`
  flex-basis: ${props => (props.xs / 12) *  100 + `%` };
  max-width: ${props => (props.xs / 12) *  100 + `%` };
  background: ${ props => props.filled ? `#fff` : `none`};
  color:${({ theme: { colors } }) => colors.greys.xdark};



  @media ${({ theme: { deviceSizes } }) => deviceSizes.minMobile} { 
    flex-basis: ${props => (props.xs / 12) *  100 + `%` };
    max-width: ${props => (props.xs / 12) *  100 + `%` };
  }

  @media ${({ theme: { deviceSizes } }) => deviceSizes.minTablet} { 
    flex-basis: ${props => (props.md / 12) *  100 + `%` };
    max-width: ${props => (props.md / 12) *  100 + `%` };
  }

  @media ${({ theme: { deviceSizes } }) => deviceSizes.minDesktop} { 
    flex-basis: ${props => props.lg ? ((props.lg / 12) *  100 + `%`) : ((props.md / 12) *  100 + `%`)};
    max-width: ${props => props.lg ? ((props.lg / 12) *  100 + `%`) : ((props.md / 12) *  100 + `%`)};
  }



 position: relative;
   
  padding: ${ props => props.padding ? props.padding : `0px`}; 
  display: ${ props => props.displayFlex ? `flex` : `initial`}; 
  align-items: ${ props => props.displayFlex ? `center` : `normal`};

  padding-left:  ${ props => props.paddSmLeft ? `${smPaddAmt}px` : ``}; 
  padding-right:  ${ props => props.paddSmRight ? `${smPaddAmt}px` : ``};  
`;

export const stackTokens: IStackTokens = {
  childrenGap: 5,
  padding: smPaddAmt,
};