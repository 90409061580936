import { UserAttribute, userAttributeFromServerModel, userAttributetoServerModel } from '../models/CustomAttribute';
import { ActivityType, getActivityType } from '../models/enums/ActivityType';
import { CustomAttributeType } from '../models/enums/CustomAttributeType';
import { FieldName } from '../models/enums/FieldName';
import { SignInType } from '../models/enums/SignInType';
import { Identity } from '../models/Identity';
import { SignIn } from '../models/SignIn';
import { TenantConfig } from '../models/TenantConfig';
import { IUser } from '../models/User';
import { getValueUpdate, UserAccountActivity } from '../models/UserAccountActivity';

export function convertSignIns(signInsResponse: any):Array<SignIn> {
    let newSignInList:Array<SignIn> = [];
    newSignInList = signInsResponse.map((signIn:any) => {
        let newSignIn: SignIn = {
            activityType: ActivityType.SignIn,
            userId: signIn.userId,
            correlationId: signIn.correlationId,
            date: new Date(signIn.date),
            city: signIn.city,
            state: signIn.state,
            countryOrRegion: signIn.countryOrRegion,
            ipAddress: signIn.ipAddress,
            appDisplayName: signIn.appDisplayName,
            appId: signIn.appId,
            status: signIn.status
        };
        return newSignIn;
    });
    return newSignInList;
}

export function convertAccountActivity(accountActivityList: any, userId:string):Array<UserAccountActivity> {
    let newAccountActivityList:Array<UserAccountActivity> = [];
    newAccountActivityList = accountActivityList.map((activity:any) => {
        let newActivity: UserAccountActivity = {
            userId: userId,
            correlationId: activity.correlationId,
            activityType: getActivityType(`Other`),
            activityDisplayName: activity.activityDisplayName,
            date: new Date(activity.activityDateTime),
            activityResult: activity.activityResult,
            category: activity.category,
            loggedByService: activity.loggedByService,
            applicationName: activity.applicationName,
            userEdited: activity.userEdited,
            policyId: activity.policyId,
            domainName: activity.domainName,
            advancedUserManagerActivity: activity.advancedUserManagerActivity,
            updatedValues: activity.updatedValues ? activity.updatedValues.map(x => getValueUpdate(x)) : []
        };
        return newActivity;
    });
    return newAccountActivityList;
}

export function iUserFromServerModel(user: any, tenantConfig: TenantConfig): IUser {
    let copiedUser = {...user};
    copiedUser.customAttributes = copiedUser.customAttributes ? copiedUser.customAttributes.map(attribute => userAttributeFromServerModel(attribute, tenantConfig)) : copiedUser.customAttributes;
    var userName = undefined;
    var email = undefined;
    var identities = copiedUser.identities ? copiedUser.identities.map((identity:any) => {
        if (identity.signInType.toLocaleLowerCase() === SignInType.EmailAddress) {
            email = identity.issuerAssignedId;
        } else if (identity.signInType.toLocaleLowerCase() === SignInType.Username) {
            userName = identity.issuerAssignedId;
        } 
        let newIdentity: Identity = {
            issuer: identity.issuer,
            issuerAssignedId: identity.issuerAssignedId,
            signInType: identity.signInType,
            oDataType: identity.oDataType,
        };
        return newIdentity;
    }) : [];
    let newUser: IUser = {
        id: copiedUser.id,
        displayName: copiedUser.displayName,
        email: email,
        userName: userName,
        firstName: copiedUser.firstName,
        lastName: copiedUser.lastName,
        accountEnabled: copiedUser.accountEnabled,
        customAttributes: copiedUser.customAttributes ? copiedUser.customAttributes.map(ca => {
            let userAttribute: UserAttribute = { 
                enabled: (ca.value !== undefined) ? true : false, // Tech Debt: Server-side model should implement "Enabled" property, or we should remove the concept from the client/ui code,
                type: ca.type,
                value: ca.value,
                name: ca.name,
                readOnly: ca.readOnly ? ca.readOnly : true,
                disableEnable: ca.disableEnable 
            };
            return userAttribute;
        }) : [],
        identities: identities
    };
    return newUser;
}

export function iUserToServerModel(user: IUser, tenantConfig: TenantConfig): IUser {
    let copiedUser = {...user};
    copiedUser.customAttributes = copiedUser.customAttributes ? copiedUser.customAttributes.map(attribute => userAttributetoServerModel(attribute, tenantConfig)) : copiedUser.customAttributes;
    return copiedUser;
}

export function copyUser(user: IUser) {
    return {...user, 
        groups: user.groups?.map(x => x),
        roles: user.roles?.map(x => x),
        identities: user.identities?.map(x => { return {...x}}),
        customAttributes: user.customAttributes?.map(x => { return {...x }})
    }
}

// This updates the user during edit and create based on the selections made in dropdowns.
// It checks for data driven permissions to remove attributes if disabled from selection, or
// replace email or username back to original value if enabled from selection - note: on create the 
// original value of these identities are undefined or empty so the update to the original value 
// is only relevant for edits.
export function updateUserUsingDropdownPermissions(user: IUser, originalUser: IUser, tenantConfig: TenantConfig) {
    var dataDrivenUsernamePermission = true;
    var dataDrivenUsernamePermissionsValid = false;
    var dataDrivenEmailPermission = true;
    var dataDrivenEmailPermissionsValid = false;
    let updatedUser = copyUser(user);
    tenantConfig.customAttributes.forEach(attribute => {
        if (attribute.type === CustomAttributeType.Dropdown) {
            let userValue = user.customAttributes?.find(userAttribute => attribute.name === userAttribute.name);
            if (userValue) {
                let option = attribute.options.find(option => option.clientValue === userValue?.value);
                if (option) {
                    option.dropdownPermissions.forEach(permission => {
                        if (permission) {
                            // During create or edits make sure any disabled fields get erased if a dropdown is updated.
                            // This is important in case we have a certain dropdown selection that forces certain fields to not
                            // be enabled and thus have no values.
                            if (!permission.isEnabled) {
                                switch (permission.attributeName) {
                                    case FieldName.LastName:
                                        updatedUser.lastName = "";
                                        break;
                                    case FieldName.FirstName:
                                        updatedUser.firstName = "";
                                        break;
                                    case FieldName.Email:
                                        dataDrivenEmailPermissionsValid = true;
                                        dataDrivenEmailPermission = false;
                                        break;
                                    case FieldName.Username:
                                        dataDrivenUsernamePermissionsValid = true;
                                        dataDrivenUsernamePermission = false;
                                        break;
                                    default: 
                                        // filter out if permission is custom attribute
                                        updatedUser.customAttributes = updatedUser.customAttributes?.filter(attribute => attribute.name !== permission.attributeName);
                                        break;
                                }
                            } else {
                                // If the edit on a dropdown has a data driven validation that has enabled username or email, 
                                // make sure we default the username or email back to the original value 
                                // incase it was disabled and erased throughout the edit.
                                switch (permission.attributeName) {
                                    case FieldName.Email:
                                    dataDrivenEmailPermissionsValid = true;
                                    dataDrivenEmailPermission = true;
                                    break;
                                    case FieldName.Username:
                                    dataDrivenUsernamePermissionsValid = true;
                                    dataDrivenUsernamePermission = true;
                                    break;
                                    default: 
                                    break;
                                }
                            }
                        }
                    });
                }
            }
        } 
    });
    var usernameIdentityEnabled = dataDrivenUsernamePermissionsValid ? dataDrivenUsernamePermission : tenantConfig.azureAttributeConfiguration.usernameEnabled;
    var emailIdentityEnabled = dataDrivenEmailPermissionsValid ? dataDrivenEmailPermission : tenantConfig.azureAttributeConfiguration.emailAddressEnabled;
    let identities = updatedUser.identities;
    if (!usernameIdentityEnabled) {
      updatedUser.userName = originalUser.userName ? originalUser.userName : ``;
      updatedUser.identities = identities?.filter(identity => identity.signInType.toLocaleLowerCase() !== 'username');
      if (originalUser.userName) {
        let oldUsernameIdentity = originalUser.identities?.find(identity => identity.signInType.toLocaleLowerCase() === 'username');
        if (oldUsernameIdentity) {
          updatedUser.identities?.push(oldUsernameIdentity)
        }
      }
    }
    if (!emailIdentityEnabled) {
      updatedUser.email = originalUser.email ? originalUser.email : ``;
      updatedUser.identities = identities?.filter(identity => identity.signInType.toLocaleLowerCase() !== 'emailaddress');
      if (originalUser.email) {
        let oldEmailIdentity = originalUser.identities?.find(identity => identity.signInType.toLocaleLowerCase() === 'emailaddress');
        if (oldEmailIdentity) {
          updatedUser.identities?.push(oldEmailIdentity)
        }
      }
    }
    return updatedUser;
}

// Uses the dropdown data driven permissions and the flags in the tenant configuration to 
// calculate if username and email address are enabled for the organization.
export function getEnabledIdentities(user: IUser, tenantConfig: TenantConfig) {
    var dataDrivenUsernamePermission = true;
    var dataDrivenUsernamePermissionsValid = false;
    var dataDrivenEmailPermission = true;
    var dataDrivenEmailPermissionsValid = false;
    tenantConfig.customAttributes.forEach(attribute => {
        if (attribute.type === CustomAttributeType.Dropdown) {
            let userValue = user.customAttributes?.find(userAttribute => attribute.name === userAttribute.name);
            if (userValue) {
                let option = attribute.options.find(option => option.clientValue === userValue?.value);
                if (option) {
                    let usernamePermission = option.dropdownPermissions.find(permission => permission.attributeName === FieldName.Username);
                    let emailPermission = option.dropdownPermissions.find(permission => permission.attributeName === FieldName.Email);
                    if (usernamePermission) {
                    dataDrivenUsernamePermissionsValid = true;
                    dataDrivenUsernamePermission = dataDrivenUsernamePermission && usernamePermission.isEnabled;
                    }
                    if (emailPermission) {
                    dataDrivenEmailPermissionsValid = true
                    dataDrivenEmailPermission = dataDrivenEmailPermission && emailPermission.isEnabled;
                    }
                }
            }
        } 
    });
    var usernameIdentityEnabled = dataDrivenUsernamePermissionsValid ? dataDrivenUsernamePermission : tenantConfig.azureAttributeConfiguration.usernameEnabled;
    var emailIdentityEnabled = dataDrivenEmailPermissionsValid ? dataDrivenEmailPermission : tenantConfig.azureAttributeConfiguration.emailAddressEnabled;  
    return { usernameIdentityEnabled, emailIdentityEnabled };
}

// Calculates the username character
export function getUsernameCharacterLimitFromDropdownPermissions(user: IUser, tenantConfig: TenantConfig) {
    let usernameCharacterLimit = NaN;
    tenantConfig.customAttributes.forEach(attribute => {
        if (attribute.type === CustomAttributeType.Dropdown) {
            let userValue = user.customAttributes?.find(userAttribute => attribute.name === userAttribute.name);
            if (userValue) {
                let option = attribute.options.find(option => option.clientValue === userValue?.value);
                if (option) {
                    let usernamePermission = option.dropdownPermissions.find(permission => permission.attributeName === FieldName.Username);
                    if (usernamePermission) {
                        if (usernamePermission.characterLimit && !isNaN(usernamePermission.characterLimit)) {
                            if (isNaN(usernameCharacterLimit)) {
                                usernameCharacterLimit = usernamePermission.characterLimit;
                            } else {
                                usernameCharacterLimit = usernamePermission.characterLimit < usernameCharacterLimit ? usernamePermission.characterLimit : usernameCharacterLimit;
                            }
                        }
                    }
                }
            }
        } 
    });
    return usernameCharacterLimit;
}

// This is used to check that the rendered attribute or action is enabled or disabled by
// the data driven permissions of dropdowns defined in the tenant config.
export function isUserAttributeEnabledByDropdown(fieldName: FieldName, tenantConfig: TenantConfig, currentUser: IUser | undefined, customAttribute: UserAttribute | undefined = undefined) : undefined | boolean {
    let fieldNameString = customAttribute ? customAttribute.name : fieldName;
    let isEnabled = true;
    let dropdownPermissionIsApplicable = false;

    // CreateUser doesn't apply here because the button should be available if enabled, the profile type is just filtered from the list.
    // FirstName and LastName are required by the Advanced User Manager, dropdown permissions do not apply here.
    if (fieldName === FieldName.SaveEdits || fieldName === FieldName.ResetPassword || fieldName === FieldName.Impersonate 
        || fieldName === FieldName.Email || fieldName === FieldName.Username || fieldName === FieldName.CustomAttribute 
        || fieldName === FieldName.DeleteUser || fieldName === FieldName.AccountEnabled) {
        tenantConfig.customAttributes.forEach(attribute => {
            if (attribute.type === CustomAttributeType.Dropdown) {
                let userValue = currentUser?.customAttributes?.find(userAttribute => attribute.name === userAttribute.name);
                if (userValue) {
                    let option = attribute.options.find(option => option.clientValue === userValue?.value);
                    if (option) {
                        let permission = option.dropdownPermissions.find(permission => { 
                            return permission.attributeName === fieldNameString 
                        });
                        if (permission) {
                            dropdownPermissionIsApplicable = true;
                            isEnabled = isEnabled && permission.isEnabled;
                        }
                    }
                }
            } 
        });
    }
    return dropdownPermissionIsApplicable ? isEnabled : undefined;
}
