import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { ActionButton, IIconProps } from 'office-ui-fabric-react';
import * as React from 'react';
import { ReactNode } from "react";
import styled from "styled-components";
import { storageURL } from '../../configurations/config';
import { loginRequest } from '../../configurations/msalConfig';
import Logo from "../shared/Logo";
import { B2CIcon } from '../../models/enums/B2CIcon';
import { RedirectRequest } from '@azure/msal-browser';
import { TenantConfig } from '../../models/TenantConfig';

// Component Props
interface IncomingProps {
    children?: ReactNode;
    accordionTitle?: string;
    tenantConfig?: TenantConfig;
    orgId?: string;
}

// Component State
interface HeaderState {
  isVisible: boolean;
}
 
const HeaderMainContainer = styled.header`
    background: ${({ theme: { colors } }) => colors.primary.main};
    color: ${({ theme: { colors } }) => colors.greys.xlight};
`;

const HeaderContainer = styled.div`
    width: 98%;
    padding: 5px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media ${({ theme: { deviceSizes } }) => deviceSizes.minMobile} { 
        width: auto;
    }
    @media ${({ theme: { deviceSizes } }) => deviceSizes.minTablet} { 
        width: 98%;
    }
`;
const MainNavigation = styled.nav``;
const HeaderButton = styled(ActionButton)`
    color:#fff;
    i{color:#fff;
        &:hover{color:${({ theme: { colors } }) => colors.secondary.default};}
    }
    &:hover{color:${({ theme: { colors } }) => colors.secondary.default};}
`;

const signInIcon: IIconProps = { iconName: B2CIcon.Signin };
const signOutIcon: IIconProps = { iconName: B2CIcon.SignOut };
const SignInSignOutButton = ({ azureAdTenantId }) => {
    const { instance } = useMsal();
    
    const redirectRequest: RedirectRequest = {
        scopes: loginRequest.scopes,
        authority: `https://login.microsoftonline.com/${azureAdTenantId}`,
       };  
    return (
        <>
            <AuthenticatedTemplate>
                <HeaderButton iconProps={signOutIcon} allowDisabledFocus onClick={() => {
                    instance.logoutRedirect(redirectRequest);
                    window.localStorage.clear();
                }}>
                    Sign Out
                </HeaderButton>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <HeaderButton iconProps={signInIcon} allowDisabledFocus onClick={() => {
                    instance.loginRedirect(redirectRequest);
                    window.localStorage.clear();
                }}>
                    Sign In
                </HeaderButton>
            </UnauthenticatedTemplate>
        </>
    );
};

class Header extends React.Component<IncomingProps, HeaderState> {
  constructor(props: any) {
      super(props);
      this.state = {
          isVisible: true, 
      };
  }
  
  render() {
    let logoPath = `${storageURL}/config/${this.props.orgId}/assets/main-logo.png`;
    let alt = this.props.tenantConfig ? this.props.tenantConfig.customUI.brandingText.headerText : ""
    return (
          <HeaderMainContainer>
            <HeaderContainer>
                <Logo imgPath={logoPath} tenantConfig={this.props.tenantConfig}/>
                <div>{alt}</div>
                <MainNavigation>              
                    <SignInSignOutButton azureAdTenantId={this.props.tenantConfig ? this.props.tenantConfig.azureAdTenantId : "common"}/>
                </MainNavigation>
            </HeaderContainer>
          </HeaderMainContainer>
      );
  }
  
}

export default Header;