import React, { ReactNode } from 'react';
import styled from "styled-components";

interface FilterContainterProps {
    children: ReactNode;
}
 
const FilterWrapper = styled.div`
    background: ${({ theme: { colors } }) => colors.greys.xmedium};  
    padding: 10px 20px;border-radius: 4px; overflow:hidden;margin: 0 20px;
    button{ background:rgba(255, 255, 255, .6); border:none;margin: 0 3px;}
`;

export const FilterContainter = (props: FilterContainterProps) => {
    return (
        <FilterWrapper id={'filterWrapper'}>
            {props.children}
        </FilterWrapper>
    );
  };
