export enum CustomAttributeType {
    String = `string`,
    Bool = `Bool`,
    Int = `Int`,
    Dropdown = `Dropdown`
}

export function getCustomAttributeTypeFromString(type: string): CustomAttributeType {
    switch (type) {
        case "string": return CustomAttributeType.String;
        case "Bool": return CustomAttributeType.Bool;
        case "Int": return CustomAttributeType.Int;
        case "Dropdown": return CustomAttributeType.Dropdown;
        default: return CustomAttributeType.String;
    }
}