import * as React from 'react';
import Header from './layout/Header';
import styled from "styled-components";
import { withRouter, RouteComponentProps } from 'react-router';

interface IAppProps {
  tenantConfig: any;
  orgId: string;
}

interface IAppState {
}

const AppContainer = styled.div`
    margin: 0 auto; height:auto; min-height: 100%;
    background: ${({ theme: { colors } }) => colors.greys.xxlight};
    display: flex;
    flex-direction: column;
`;

class App extends React.Component<RouteComponentProps<any> & IAppProps, IAppState> {
  public constructor(props: RouteComponentProps<any> & any) {
    super(props);
    this.state = { };
  }

  render() {
    return (
      <>
      <Header tenantConfig={this.props.tenantConfig} orgId={this.props.orgId}/>
      <AppContainer>
            {this.props.children}
      </AppContainer>
      </>
    );
  }
}

export default withRouter(App);
