import  React from "react";
import { ReactNode } from "react";
import { ThemeProvider } from 'styled-components';

interface IProps {
    children: ReactNode;
}

const mobileWidth: number = 320;
const mobileLWidth: number = 425;
const mobileXLWidth: number = 576;
const tabletWidth: number = 768;
const laptopWidth: number = 1024;
const laptopLWidth: number = 1440;
const desktopWidth: number = 2560;

// Netrix Default Theme
const defaultTheme = {
  colors: {
    powderWhite: `#FFFDF9`,
    white: `#FFFFFF`,
    onyx: `#36313D`,
    darkGrey: `#333333`,
    red:`#ff3434`,
    green: {
      success: `#92c353`
    },
    // Primary and Secondary will be overwritten from Tenat json file
    primary: {
      main: `#2e1a47`,
      light: `#1f1230`,
      xlight: `#825dc7`
    },
    secondary: {
      alert: `#2ea3f2`,
      default: `#535b7c`
    },
    //
    greys: {
      dark: `#5b606e`,
      xdark: `#3c404a`, 
      medium: `#7b7f8b`,
      xmedium: `#f0f2f7`,
      light: `#babfce`,
      xlight: `#d0d0d0`,
      xxlight: `#e7e7e7`
    }
  },
  padding: {
    large: `40px`,
    medium: `30px`,
    small: `20px`
  },
  fonts: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif`,
  fontSizes: {
    smallest: `.7em`,
    small: `.9em`,
    smallX: `1.5em`,
    medium: `2em`,
    large: `3em`,
    titleSmall: `1.2em`,
    titleLarger: `1.9em`
  },
  deviceSizes: {
    minMobile: `(min-width: ${mobileWidth}px)`,
    maxMobile: `(max-width: ${mobileLWidth - 1}px)`,
    minMobileL: `(min-width: ${mobileLWidth}px)`,
    maxMobileL: `(max-width: ${mobileXLWidth -1}px)`,
    minMobileXL: `(min-width: ${mobileXLWidth}px)`,
    maxMobileXL: `(max-width: ${tabletWidth - 1}px)`,
    minTablet: `(min-width: ${tabletWidth}px)`,
    maxTablet: `(max-width: ${laptopWidth - 1}px)`,
    minLaptop: `(min-width: ${laptopWidth}px)`,
    maxLaptop: `(max-width: ${laptopLWidth - 1}px)`,
    minLaptopL: `(min-width: ${laptopLWidth}px)`,
    maxLaptopL: `(max-width: ${desktopWidth - 1}px)`,
    minDesktop: `(min-width: ${desktopWidth}px)`
  }
};

export const smPaddAmt = 10;
 
const Theme = ({ children, ...props }: IProps) => (
  <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
);

export default Theme;
