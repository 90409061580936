import axios, { AxiosRequestConfig } from 'axios';
import { storageURL } from '../configurations/config';
import { msalConfig, loginRequest } from '../configurations/msalConfig';
import { getTenantConfigFailure, getTenantConfigSuccess, initializeGetTenantConfig } from '../store/slices/tenantConfigReducer';
import * as msal from "@azure/msal-browser";
import { getGroupAccessToken } from '../services/authenticationServices';
  
export function fetchPrivateOrganizationConfiguration(orgId: string, azureAdTenantId: string) {  
  return async dispatch => {
    if (orgId !== undefined && orgId !== '') {
      dispatch(initializeGetTenantConfig());
      try {
        const silentRequest: any = {
          scopes: loginRequest.scopes,
          authority: `https://login.microsoftonline.com/${azureAdTenantId}`
        };
        
        const msalInstance = new msal.PublicClientApplication(msalConfig(azureAdTenantId));
        if (msalInstance.getAllAccounts() && msalInstance.getAllAccounts().length > 0) {
          silentRequest.loginHint = msalInstance.getAllAccounts()[0].username;
        
          const loginResponse = await msalInstance.ssoSilent(silentRequest);
          if (loginResponse.accessToken) {
            
            // Check if the groups claim was too large to return to MSAL on client,
            // if so then send groups access token back for API to resolve groups.
            let groupsAccessToken = await getGroupAccessToken(loginResponse, azureAdTenantId);
            
            const authorizedResponse = await axios.get(`${storageURL}/config/${orgId}/privatetenant.json`, 
              { method: `GET`, 
                headers: 
                  { 'Accept': `application/json`, 
                    'Authorization': `Bearer ${loginResponse.accessToken}`,
                    'OrganizationId': `${orgId}`,
                    'GroupsAuthorization': `Bearer ${groupsAccessToken}`
                  } 
              });    
            if (authorizedResponse.data) {
              authorizedResponse.data.orgId = orgId;
              authorizedResponse.data.isPrivateConfiguration = true;
              dispatch(getTenantConfigSuccess(authorizedResponse.data));
            } else {
              let error = new Error(`Unexpected error while fetching users`);
              (error as any).response = authorizedResponse.data;
              throw error;
            } 
          } else {
            let error = new Error(`User not authorized`);
            throw error;
          }
        } else {
          let error = new Error(`User not authorized`);
          throw error;
        }
      } catch (main_error) {
        dispatch(getTenantConfigFailure());
      }
    } else {
      dispatch(getTenantConfigFailure());
    }
  };
}

export function fetchOrganizationConfiguration(orgId: string, redirect: boolean) {  
  const config: AxiosRequestConfig = {
    method: `GET`,
    headers: {
      'Accept': `application/json`
    }
  };
  return async dispatch => {
    if (orgId !== undefined && orgId !== '') {
      dispatch(initializeGetTenantConfig());
      try {
        const response = await axios.get(`${storageURL}/config/${orgId}/tenant.json`, config);     
        if (response.data) {
          response.data.orgId = orgId;
          response.data.isPrivateConfiguration = false;
          dispatch(getTenantConfigSuccess(response.data));
        } else {
            var error = new Error(`Unexpected error while fetching users`);
            (error as any).response = response.data;
            throw error;
        }
      } catch (main_error) {
        dispatch(getTenantConfigFailure());
      }
    } else if (redirect) {
      dispatch(initializeGetTenantConfig());
    } else {
      dispatch(getTenantConfigFailure());
    }
  };
}