import { msalConfig,loginRequest } from '../configurations/msalConfig';
import * as msal from "@azure/msal-browser";
import { initializeGetAuthenticatedUser, getAuthenticatedUserSuccess, getAuthenticatedUserFailure  } from '../store/slices/authentication';
import { TenantConfig } from '../models/TenantConfig';
import { getAuthenticatedUserRole } from '../api/userApi';

export async function getGroupAccessToken(authenticationResponse: any, azureAdTenantId: string) {
    
    if (!authenticationResponse.account.idTokenClaims.groups) {
        const silentRequestGroups: any = {
            scopes: ["https://graph.windows.net/.default"],
            authority: `https://login.microsoftonline.com/${azureAdTenantId}`
        };
        const msalInstanceGroups = new msal.PublicClientApplication(msalConfig(azureAdTenantId));
        if (msalInstanceGroups.getAllAccounts() && msalInstanceGroups.getAllAccounts().length > 0) {
        silentRequestGroups.loginHint = msalInstanceGroups.getAllAccounts()[0].username;
        }
        let authenticationResult;
        try{
            authenticationResult = await msalInstanceGroups.ssoSilent(silentRequestGroups);
        
        }
        catch {
            authenticationResult = await msalInstanceGroups.acquireTokenRedirect(silentRequestGroups);
        }
        return authenticationResult.accessToken;
    }
    return "";
}

export function checkAndSetActiveAccount(tenantConfig: TenantConfig){
    return async dispatch => {
        dispatch(initializeGetAuthenticatedUser());
        try {
                const silentRequest: any = {
                    scopes: loginRequest.scopes,
                    authority: `https://login.microsoftonline.com/${tenantConfig.azureAdTenantId}`
                };
                const msalInstance = new msal.PublicClientApplication(msalConfig(tenantConfig.azureAdTenantId));
                
                if (msalInstance.getAllAccounts() && msalInstance.getAllAccounts().length > 0) {
                    silentRequest.loginHint = msalInstance.getAllAccounts()[0].username;
                }
                let authenticationResult;
                try{
                     authenticationResult = await msalInstance.ssoSilent(silentRequest);
                    
                }
                catch {
                    authenticationResult = await msalInstance.acquireTokenRedirect(silentRequest);
                }
                if (authenticationResult !== null && authenticationResult.accessToken) {
                    
                    // Check if the groups claim was too large to return to MSAL on client,
                    // if so then send groups access token back for API to resolve groups.
                    let groupsAccessToken = await getGroupAccessToken(authenticationResult, tenantConfig.azureAdTenantId);
                    
                    getAuthenticatedUserRole(tenantConfig.orgId, authenticationResult.accessToken, groupsAccessToken).then(response => {
                        authenticationResult.account.idTokenClaims.roles = [response];
                        authenticationResult.groupsAccessToken = groupsAccessToken;
                        dispatch(getAuthenticatedUserSuccess(authenticationResult));
                
                    }).catch(response => {
                        dispatch(getAuthenticatedUserFailure());
                    });
                } else {
                    var error = new Error(`Unexpected error while authenticating user.`);
                    (error as any).response = authenticationResult;
                    throw error;
                }
                
            } catch (main_error) { 
                dispatch(getAuthenticatedUserFailure());
            }
        };
};
